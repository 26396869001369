<template>
    <div>
        <v-progress-circular indeterminate v-if="loading"/>
        <template v-else-if="isAuthenticated">
            <span v-if="!hideCashback">
                {{ valueBalance | currency }}
                <div v-if="displayMode == 'POINTS' && showPoints" class="overline mt-1">
                    {{ amountBalance | dynamicPoint }}
                </div>
            </span>
            <span v-else>
                {{ amountBalance | dynamicPoint }}
            </span>
        </template>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    name: 'Balance',
    props: {
        showPoints: {
            type: Boolean,
            required: false,
            default: true,
        }
    },
    data: vm => ({
        loading: true,
        error  : false,
    }),
    methods: {
        async fetchBalance() {
            if (!this.isAuthenticated)
                return

            this.loading = true
            this.error   = false

            let response = await this.$axios({
                url: 'customer/balance',
                method: 'get',
            }).catch(this.preErrorHandler)

            if (!response || response.status != 200)
                return

            response.data.amount_balance = response.data.amount_balance || 0
            response.data.value_balance  = response.data.value_balance || 0

            // Se o balance mudou desde a última verificação, então o nível pode ter mudado e o customer deve ser atualizado
            if (!this.isFirstBalanceUpdate && // não precisa atualizar no primeiro update pois já é chamado o auth/fetchCurrentCustomer no App.vue
                (this.amountBalance != response.data.amount_balance || this.valueBalance != response.data.value_balance)
            ) {
                // Atualiza o customer que está na store, não forçando a atualização de nível, pois no generate isso já é feito
                await this.$store.dispatch('auth/fetchCurrentCustomer', false)
            }

            // Atualiza na view os valores do balance
            this.amountBalance = response.data.amount_balance
            this.valueBalance  = response.data.value_balance

            this.isFirstBalanceUpdate = false
            this.loading              = false
        },
        preErrorHandler(e) {
            this.loading = false
            this.error   = true
        },
    },
    computed: {
        ...mapGetters({
            displayMode    : 'company/display_mode',
            hideCashback   : 'company/hide_cashback',
            isAuthenticated: 'auth/check',
        }),

        // Quando esta variável é alterada, mexe diretamente na store
        isFirstBalanceUpdate: {
            get() {
                return this.$store.getters['balance/isFirstBalanceUpdate']
            },
            set(v) {
                this.$store.commit('balance/SET_IS_FIRST_BALANCE_UPDATE', v)
            },
        },
        amountBalance: {
            get() {
                return this.$store.getters['balance/amountBalance']
            },
            set(v) {
                this.$store.commit('balance/SET_AMOUNT_BALANCE', v)
            },
        },
        valueBalance: {
            get() {
                return this.$store.getters['balance/valueBalance']
            },
            set(v) {
                this.$store.commit('balance/SET_VALUE_BALANCE', v)
            },
        },
    },
    watch: {
        /**
         * Sempre que mudar de rota, verifica se o balance continua o mesmo e atualiza este componente
         */
        '$route.name': {
            handler() {
                this.fetchBalance()
            },
            immediate: true,
        },
    },
}
</script>