export default [
    {
        path: '/perguntas-frequentes',
        name: 'faq',
        props: true,
        component: () => import(/* webpackChunkName: "faq" */ '@/views/faq/FaqView.vue'),
        meta: {
            mustHaveCompleteProfile : true,
            requiresAuthentication  : true,
            title                   : 'faq',
        },
    }
]