import '@fortawesome/fontawesome-free/css/all.css'
import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import i18n from '@/i18n'
import '@/scss/overrides.scss'

// Importação de ícones
import DollarIcon      from '@/shared/components/icons/DollarIcon.vue'
import GiftIcon        from '@/shared/components/icons/GiftIcon.vue'
import HomeIcon        from '@/shared/components/icons/HomeIcon.vue'
import BagShoppingIcon from '@/shared/components/icons/BagShoppingIcon.vue'
import AwardIcon       from '@/shared/components/icons/AwardIcon'
import CreditCardIcon  from '@/shared/components/icons/CreditCardIcon'
import BoxIcon         from '@/shared/components/icons/BoxIcon'

import BalanceIcon    from '@/components/icons/BalanceIcon.vue'
import BagShopIcon    from '@/components/icons/BagShopIcon.vue'
import HelpCircleIcon from '@/components/icons/HelpCircleIcon.vue'
import ListIcon       from '@/components/icons/ListIcon.vue'
import StarIcon       from '@/components/icons/StarIcon.vue'
import UserIcon       from '@/components/icons/UserIcon.vue'
import LogoutIcon     from '@/components/icons/LogoutIcon.vue'
import AlertCircle    from '@/components/icons/AlertCircle.vue'
import FilterIcon     from '@/components/icons/FilterIcon.vue'
import AlertOctagon   from '@/components/icons/AlertOctagon.vue'

Vue.use(Vuetify)

export default new Vuetify({
    // https://feathericons.com/
    icons: {
        iconfont: 'fa',
        values: {
            next: 'fas fa-caret-right',
            prev: 'fas fa-caret-left',
            clear: 'fas fa-times',
            cancel: 'fas fa-times',
            cp_balance: {
                component: BalanceIcon,
            },
            cp_dollar: {
                component: DollarIcon,
            },
            cp_gift: {
                component: GiftIcon,
            },
            cp_bag: {
                component: BagShopIcon,
            },
            cp_bag_shopping: {
                component: BagShoppingIcon,
            },
            cp_help_circle: {
                component: HelpCircleIcon,
            },
            cp_home: {
                component: HomeIcon,
            },
            cp_list: {
                component: ListIcon,
            },
            cp_star: {
                component: StarIcon,
            },
            cp_user: {
                component: UserIcon,
            },
            cp_logout: {
                component: LogoutIcon,
            },
            cp_alert: {
                component: AlertCircle,
            },
            cp_alert_octagon: {
                component: AlertOctagon,
            },
            cp_filter: {
                component: FilterIcon ,
            },
            cp_award: {
                component: AwardIcon,
            },
            cp_credit_card: {
                component: CreditCardIcon,
            },
            cp_box: {
                component: BoxIcon,
            },
            // Customizados para contexto [TODO] alterar para feathericons
            cp_promotion: 'fas fa-fw fa-percentage',
            cp_generate_point: 'fas fa-fw fa-plus-circle',
        },
    },
    theme: {
        options: {
            customProperties: true,
        },
        themes: {
            light: {
                // customer choices
                primary   : '#1976D2',
                secondary : '#424242',
                font      : '#FFFAFF',
 
                // system

                // general
                navbar       : '#FBFBFD',
                sections     : '#FFFFFF',
                background   : '#F6F7FB',
                accent       : '#82B1FF',
                error        : '#FF5252',
                info         : '#2196F3',
                success      : '#4CAF50',
                sucesss_green: '#90c436',
                warning      : '#FF5722',

                // fonts
                primary_font  : '#3B4050',
                secondary_font: '#9A9CB8',

                // tables
                table_divider_color: '#F9F9FB',

                progress_linear: '#F4F1F1'
            },
        },
    },
    lang: {
        t: (key, ...params) => i18n.t(key, params)
    },
})
