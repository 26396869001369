var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isVisible
    ? _c(
        "v-dialog",
        _vm._g(
          _vm._b(
            {
              attrs: {
                width: _vm.width,
                "max-width": _vm.maxWidth,
                persistent: _vm.persistent
              },
              on: {
                keydown: function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.handleConfirm($event)
                }
              },
              model: {
                value: _vm.isVisible,
                callback: function($$v) {
                  _vm.isVisible = $$v
                },
                expression: "isVisible"
              }
            },
            "v-dialog",
            _vm.attrs,
            false
          ),
          _vm.listeners
        ),
        [
          _vm._t("content", [
            _c(
              "v-card",
              { staticClass: "pa-2" },
              [
                _vm.customConfirm
                  ? _c(
                      "div",
                      { staticClass: "mt-9", class: _vm.customConfirmClass },
                      [[_vm._t("icon")]],
                      2
                    )
                  : _vm._e(),
                _vm.hasTitle
                  ? _c(
                      "v-card-title",
                      {
                        class: _vm.customConfirmClass,
                        attrs: { color: "success" }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass:
                              "primary--text font-weight-bold body-1 card-text"
                          },
                          [_vm._v(_vm._s(_vm.title))]
                        )
                      ]
                    )
                  : _vm._e(),
                _vm.hasSubtitle
                  ? _c(
                      "v-card-subtitle",
                      {
                        staticClass: "pt-2 card-text",
                        class: _vm.customConfirmClass
                      },
                      [_vm._v(" " + _vm._s(_vm.subtitle) + " ")]
                    )
                  : _vm._e(),
                _vm.hasText
                  ? _c(
                      "v-card-text",
                      { staticClass: "py-3 mb-4 text-center card-text" },
                      [
                        _c("span", { staticClass: "primary_font--text" }, [
                          _vm._v(_vm._s(_vm.text))
                        ]),
                        _vm._t("text")
                      ],
                      2
                    )
                  : _vm._e(),
                _vm.hasActions
                  ? _c(
                      "v-card-actions",
                      {
                        staticClass: "mb-3 mx-6 pa-0",
                        class: _vm.customConfirmClass
                      },
                      [
                        !_vm.customConfirm ? _c("v-spacer") : _vm._e(),
                        _vm.hasCancel
                          ? _c(
                              "v-btn",
                              {
                                attrs: {
                                  elevation: "0",
                                  color: _vm.cancelColor,
                                  disabled: _vm.cancelDisabled,
                                  height: "36px",
                                  width: "86px"
                                },
                                on: { click: _vm.handleCancel }
                              },
                              [_vm._v(" " + _vm._s(_vm.cancel) + " ")]
                            )
                          : _vm._e(),
                        _vm.hasConfirm
                          ? _c(
                              "v-btn",
                              {
                                attrs: {
                                  color: _vm.confirmColor,
                                  disabled: _vm.confirmDisabled,
                                  depressed: "",
                                  elevation: "0",
                                  height: "36px",
                                  width: "86px"
                                },
                                on: {
                                  click: _vm.handleConfirm,
                                  keyup: function($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.handleConfirm($event)
                                  }
                                }
                              },
                              [_vm._v(" " + _vm._s(_vm.confirm) + " ")]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            )
          ])
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }