var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-navigation-drawer",
    {
      attrs: {
        permanent: _vm.$vuetify.breakpoint.mdAndUp,
        "mini-variant": _vm.$vuetify.breakpoint.mdAndUp && _vm.mini,
        app: _vm.$vuetify.breakpoint.smAndDown,
        absolute: _vm.$vuetify.breakpoint.mdAndUp,
        value: _vm.value,
        "disable-resize-watcher": "",
        "hide-overlay": _vm.mini
      },
      on: {
        input: _vm.onInput,
        click: function($event) {
          _vm.mini = !_vm.mini
        }
      }
    },
    [
      _c(
        "v-list-item",
        [
          _c(
            "v-list-item-content",
            [
              _c(
                "v-row",
                [
                  _vm.$vuetify.breakpoint.smAndDown || !_vm.mini
                    ? _c(
                        "v-col",
                        { attrs: { cols: "10" } },
                        [
                          _c("v-list-item-title", { staticClass: "title" }, [
                            _vm._v(" " + _vm._s(_vm.programName) + " ")
                          ]),
                          _c("v-list-item-subtitle", [
                            _vm._v(
                              " " + _vm._s(_vm.$t("drawer.subtitle")) + " "
                            )
                          ]),
                          _vm.$vuetify.breakpoint.xs
                            ? _c(
                                "v-row",
                                {
                                  staticClass:
                                    "mx-0 caption secondary_font--text mt-1"
                                },
                                [
                                  _c("div", { staticClass: "mr-1" }, [
                                    _vm._v(_vm._s(_vm.$t("drawer.balance")))
                                  ]),
                                  _c("Balance", {
                                    attrs: { showPoints: false }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.$vuetify.breakpoint.mdAndUp && !_vm.mini
                    ? _c(
                        "v-col",
                        { attrs: { cols: "2" } },
                        [
                          _c(
                            "v-icon",
                            {
                              staticClass:
                                "px-2 py-1 mt-2 mr-1 close-drawer-icon",
                              attrs: { color: "primary" },
                              on: {
                                click: function($event) {
                                  _vm.mini = true
                                }
                              }
                            },
                            [_vm._v(" fas fa-angle-left ")]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.$vuetify.breakpoint.mdAndUp && _vm.mini
                    ? _c(
                        "v-col",
                        { staticClass: "pt-2 pb-5", attrs: { cols: "12" } },
                        [
                          _c(
                            "v-icon",
                            {
                              attrs: { color: "primary" },
                              on: {
                                click: function($event) {
                                  _vm.mini = false
                                }
                              }
                            },
                            [_vm._v(" fas fa-bars ")]
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-list",
        { staticClass: "drawer-list", attrs: { dense: "", nav: "" } },
        [
          _vm.isAuthenticated
            ? _vm._l(_vm.items, function(item) {
                return _c(
                  "v-list-item",
                  {
                    key: item.title,
                    attrs: {
                      link: "",
                      to: item.to,
                      disabled: item.disabled,
                      hidden: item.hidden
                    },
                    on: {
                      click: function($event) {
                        _vm.mini = true
                      }
                    }
                  },
                  [
                    _c(
                      "v-list-item-icon",
                      { staticClass: "d-flex justify-center" },
                      [
                        _c(
                          "v-icon",
                          {
                            staticClass: "list-icon",
                            attrs: { size: 20, color: "primary" }
                          },
                          [_vm._v(_vm._s(item.icon))]
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-list-item-content",
                      [
                        _c(
                          "v-list-item-title",
                          { staticClass: "align-right" },
                          [_vm._v(_vm._s(item.title))]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              })
            : [
                _c(
                  "v-list-item",
                  { attrs: { link: "", to: { name: "login" } } },
                  [
                    _c(
                      "v-list-item-icon",
                      [_c("v-icon", [_vm._v("fas fa-sign-in-alt")])],
                      1
                    ),
                    _c(
                      "v-list-item-content",
                      [
                        _c("v-list-item-title", [
                          _vm._v(_vm._s(_vm.$t("actions.login")))
                        ])
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-list-item",
                  { attrs: { link: "", to: { name: "login" } } },
                  [
                    _c(
                      "v-list-item-icon",
                      [_c("v-icon", [_vm._v("fas fa-user")])],
                      1
                    ),
                    _c(
                      "v-list-item-content",
                      [
                        _c("v-list-item-title", [
                          _vm._v(_vm._s(_vm.$t("actions.register")))
                        ])
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-list-item",
                  { attrs: { link: "" }, on: { click: _vm.welcome } },
                  [
                    _c(
                      "v-list-item-icon",
                      [_c("v-icon", [_vm._v("fas fa-info-circle")])],
                      1
                    ),
                    _c(
                      "v-list-item-content",
                      [
                        _c("v-list-item-title", [
                          _vm._v(_vm._s(_vm.$t("drawer.welcome")))
                        ])
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
        ],
        2
      ),
      !_vm.isAuthenticated
        ? _c(
            "v-list-item",
            [
              _c(
                "v-list-item-content",
                [
                  _c("v-list-item-subtitle", [
                    _c("p", [_vm._v(_vm._s(_vm.$t("drawer.auth_needed")))])
                  ])
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }