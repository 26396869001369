import moment from 'moment'
import lodash from 'lodash'
import $console from './console'
import locale from './locale'
import bus from './bus'
import axios from './axios'
import constants from './constants'
import firebase from './firebase'
import persistence from './persistence'

let instaled = false

// Plugin install
const install = Vue => {

    if (instaled) return // Plugin já foi instalado

    Object.defineProperties(Vue.prototype, {
        $moment: {
            get() {
                return moment
            }
        },
        $lodash: {
            get() {
                return lodash
            }
        },
        $console: {
            get() {
                return $console
            }
        },
        $bus: {
            get() {
                return bus
            }
        },
        $locale: {
            get() {
                return locale
            }
        },
        $axios: {
            get() {
                return axios
            }
        },
        $constants: {
            get() {
                return constants
            }
        },
        $firebase: {
            get() {
                return firebase
            }
        },
        $persistence: {
            get() {
                return persistence
            }
        },
    })

    instaled = true
}

export default install