var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.loading
        ? _c("v-progress-circular", { attrs: { indeterminate: "" } })
        : _vm.isAuthenticated
        ? [
            !_vm.hideCashback
              ? _c("span", [
                  _vm._v(
                    " " + _vm._s(_vm._f("currency")(_vm.valueBalance)) + " "
                  ),
                  _vm.displayMode == "POINTS" && _vm.showPoints
                    ? _c("div", { staticClass: "overline mt-1" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm._f("dynamicPoint")(_vm.amountBalance)) +
                            " "
                        )
                      ])
                    : _vm._e()
                ])
              : _c("span", [
                  _vm._v(
                    " " +
                      _vm._s(_vm._f("dynamicPoint")(_vm.amountBalance)) +
                      " "
                  )
                ])
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }