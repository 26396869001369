var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("BaseModal", {
    ref: "modal",
    attrs: {
      title: _vm.title,
      subtitle: _vm.subtitle,
      hasCancel: false,
      confirm: _vm.confirm,
      confirmColor: _vm.color,
      "max-width": "400px"
    },
    on: { closed: _vm.onClosed },
    scopedSlots: _vm._u([
      {
        key: "text",
        fn: function() {
          return [
            !_vm.useHtml
              ? [_vm._v(" " + _vm._s(_vm.text) + " ")]
              : _c("div", { domProps: { innerHTML: _vm._s(_vm.text) } })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }