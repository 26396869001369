var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "35",
        height: "35",
        viewBox: "0 0 35 35"
      }
    },
    [
      _c("path", {
        staticClass: "a",
        attrs: {
          d:
            "M20.781,8.75H17.5V6.562a6.563,6.563,0,0,0-13.125,0V8.75H1.094A1.094,1.094,0,0,0,0,9.844V30.625A4.379,4.379,0,0,0,4.375,35h7.656a1.094,1.094,0,0,0,0-2.187H4.375a2.19,2.19,0,0,1-2.188-2.188V10.937H4.375v1.094a1.094,1.094,0,0,0,2.187,0V10.937h8.75v1.094a1.094,1.094,0,0,0,2.188,0V10.937h2.188v1.094a1.094,1.094,0,0,0,2.188,0V9.844A1.094,1.094,0,0,0,20.781,8.75Zm-5.469,0H6.563V6.562a4.375,4.375,0,0,1,8.75,0Z"
        }
      }),
      _c("g", { attrs: { transform: "translate(15.313 13.125)" } }, [
        _c("path", {
          staticClass: "a",
          attrs: {
            d:
              "M242.594,196.375H239.2a5.467,5.467,0,0,0-10.714,0h-3.393A1.094,1.094,0,0,0,224,197.469V209.5a4.379,4.379,0,0,0,4.375,4.375h10.938a4.379,4.379,0,0,0,4.375-4.375V197.469A1.094,1.094,0,0,0,242.594,196.375Zm-8.75-2.187a3.275,3.275,0,0,1,3.08,2.188h-6.16A3.275,3.275,0,0,1,233.844,194.188ZM241.5,209.5a2.19,2.19,0,0,1-2.187,2.188H228.375a2.19,2.19,0,0,1-2.187-2.187V198.563h2.188v1.094a1.094,1.094,0,0,0,2.188,0v-1.094h6.563v1.094a1.094,1.094,0,0,0,2.188,0v-1.094H241.5Z",
            transform: "translate(-224 -192)"
          }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }