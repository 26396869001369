export default {
    DateInput: {
        min_date: 'A data deve ser maior que {min}',
        max_date: 'A data deve ser menor que {max}',
        invalid_date: 'Data inválida',
        required: 'Este campo é obrigatório',
        invalid_format: 'Formato inválido',
    },
    GenderSelectInput: {
        male: 'Masculino',
        female: 'Feminino',
        others: 'Outros (transgênero, não binário, etc)',
        not_answer : 'Prefiro não responder',

    },
}