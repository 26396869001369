<template>
    <v-alert
        v-model="isOpen" 
        dismissible
        text
        color="primary"
        :icon="showIcon"
        class="my-0"
        @input="closeAlert()"
    >
        <v-row align="center">
            <v-col class="py-0" cols="12" md="6">{{ text }}</v-col>
            <v-col class="mr-5 py-0 d-flex justify-md-end ">
                <span v-if="redirect">
                    <a class="primary--text pointer link" 
                        :href="action_link" 
                        target="_blank"
                    >{{ action_text }}</a>
                </span>
                <span v-if="!redirect && !$lodash.isEmpty(action_router)">
                    <router-link class="primary--text pointer link mr-auto" 
                        :to="action_router"
                    >{{ action_text }}</router-link>
                </span>
            </v-col>
        </v-row>
    </v-alert>
</template>

<script>
import $persistence from '@/services/persistence'

export default {
    name: 'GeneralAlert',
    data: vm => ({
        isOpen          : false,
        redirect        : false,
        text            : '',
        action_link     : '',
        action_text     : '',
        action_router   : {},
        localStorageConfig: null,
    }),
    methods: {
        new(message, configurations = {}) {
            if (typeof configurations != 'object')
                throw new TypeError('Expected configurations to be an object')

            this.text          = message
            this.redirect      = configurations.redirect
            this.action_link   = configurations.action_link
            this.action_router = configurations.action_router
            this.action_text   = configurations.action_text

            this.localStorageConfig = configurations.localStorageConfig

            let itemKey = this.$lodash.get(this.localStorageConfig, 'itemKey')
            let value   = $persistence.getItem(itemKey)

            if (value) {
                this.isOpen = false
                return
            }

            this.isOpen = true
        },
        closeAlert() {
            let itemKey   = this.$lodash.get(this.localStorageConfig, 'itemKey'  )
            let value     = this.$lodash.get(this.localStorageConfig, 'value'    )
            let expiresIn = this.$lodash.get(this.localStorageConfig, 'expiresIn')

            if (!itemKey || !value)
                return

            $persistence.setItem(itemKey, value, expiresIn)
        }
    },
    computed: {
        showIcon() {
            // Em telas muitos pequenas os textos ficam desalinhados com icone de alerta
            return this.$vuetify.breakpoint.width < 400 ? false : '$cp_alert'
        }
    },
    watch: {
        '$route.name'() {
            if (this.$route.name == this.action_router.name)
                this.isOpen = false
        },
    },
}
</script>

<style>
.link {
    text-decoration: none;
    font-weight: 600;
    font-size: 14px;
}
</style>