var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "b",
      staticStyle: {
        "-ms-transform": "rotate(360deg)",
        "-webkit-transform": "rotate(360deg)",
        transform: "rotate(360deg)"
      },
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        width: "1em",
        height: "1em",
        preserveAspectRatio: "xMidYMid meet",
        viewBox: "0 0 24 24"
      }
    },
    [
      _c("g", [
        _c("path", {
          attrs: {
            d: "M7.86 2h8.28L22 7.86v8.28L16.14 22H7.86L2 16.14V7.86L7.86 2z"
          }
        }),
        _c("path", { attrs: { d: "M12 8v4" } }),
        _c("path", { attrs: { d: "M12 16h.01" } })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }