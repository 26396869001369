export default {
    register_self: 'Cadastre-se',
    register: 'Cadastrar',
    login: 'Entrar',
    logout: 'Sair',
    submit: 'Submeter',
    send: 'Enviar',
    back: 'Voltar',
    update: 'Atualizar',
    has_account: 'Já tenho conta',
    accept: 'Autorizar',
    not_accept: 'Não Autorizar',
    cancel: 'Cancelar',
    buy: 'Comprar',
    close: 'Fechar',
    search: 'Buscar',
}