import $store from '@/store'

/**
 * Este serviço lida com as verificações das autorizações
 */
export default new class CheckAuthorizationService {

    constructor() {
        this.INTERVAL_TIMEOUT = process.env.VUE_APP_CHECK_INTENTION_INTERVAL || 10000
    }

    setup(context) {
        this.check(context)

        setInterval(() => {
            this.check(context)
        }, this.INTERVAL_TIMEOUT)
    }

    async check(context) {
        if (!context)
            throw new Error('Deve passar um contexto para atualizar as autorizações.')

        if (!$store.getters['auth/check'])
            return

        await $store.dispatch('authorization/getAuthorization')
    }

}