
export default [
    {
        path: '/completar-cadastro',
        name: 'complete-profile',
        component: () => import(/* webpackChunkName: "cp" */ '@/views/profile/CompleteProfileView.vue'),
        props: true,
        meta: {
            requiresAuthentication: true,
            noDrawer              : true,
            isSimpleLogout        : true,
            title                 : 'complete-profile',
        },
    },
]