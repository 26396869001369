import $store         from '@/store'
import $console       from '@/services/console'
import SimpleCustomer from '../models/SimpleCustomer'
import firebaseAppKey from '@/assets/secrets/firebase_app_key.json'

function validateAppKey(appKey) {
    const expectedFields = [
        'apiKey',
        'authDomain',
        'databaseURL',
        'projectId',
        'storageBucket',
        'messagingSenderId',
        'appId',
    ]

    return expectedFields.every(field => !!appKey[field])
}

if (!validateAppKey(firebaseAppKey))
    throw new TypeError('appKey is missing expected fields')

export default new class FirebaseService {
    constructor() {
        this.waitPromiseResolve = null
        this.waitPromiseReject = null

        this.isFirebaseInitialized = false
    }

    async setup(context) {
        // Trata caso seja chamado mais que uma vez
        if (this.isFirebaseInitialized) {
            $console.warn('Firebase is already initialized.')
            return
        }

        let firebaseConfig = await this.getFirebaseConfigFromCompany()

        // Initialize Firebase
        window.firebase.initializeApp(firebaseConfig)
        this.isFirebaseInitialized = true

        // Turn off phone auth app verification.
        window.firebase.auth().settings.appVerificationDisabledForTesting = ['development'].includes(process.env.NODE_ENV)

        // Quando um usuário autentica ou sai em outra aba, deve atualizar esta também
        await window.firebase.auth()
            .onAuthStateChanged(async user => user ? await this.onUser(user) : await this.onGuest(user))

        await this.waitFetchUserIfAuthenticated()
    }

    async logout() {
        $console.log('[firebase] sign out')
        await window.firebase.auth().signOut()
    }

    async onUser(firebaseResult) {
        // firebaseResult is signed in.
        // var displayName = firebaseResult.displayName;
        // var email = firebaseResult.email;
        // var emailVerified = firebaseResult.emailVerified;
        // var photoURL = firebaseResult.photoURL;
        // var isAnonymous = firebaseResult.isAnonymous;
        // var uid = firebaseResult.uid;
        // var providerData = firebaseResult.providerData;
        $console.log('[firebase] user logged in', firebaseResult)

        let credentials = new SimpleCustomer({
            name          : firebaseResult.displayName,
            email         : firebaseResult.email,
            avatar_url    : firebaseResult.photoURL,
            phone         : firebaseResult.phoneNumber,
            firebaseResult,
        })

        await $store.dispatch('auth/login', credentials)
            .catch(e => {
                typeof this.waitPromiseReject === 'function' && this.waitPromiseReject(e)
                // Nesse caso queremos que seja exibido erro no console
                console.error(e) // eslint-disable-line no-console
                return
            })

        typeof this.waitPromiseResolve === 'function' && this.waitPromiseResolve()
    }

    async onGuest(user) {
        $console.log('[firebase] onGuest')

        // Se estava autenticado, precisa fazer login de novo
        if ($store.getters['auth/check'] && $store.getters['auth/token_type'] == 'FIREBASE') {
            await $store.dispatch('auth/logout')
        }
        // Se não estava autenticado, não precisa fazer nada
    }

    async getFirebaseConfigFromCompany() {
        // $console.log('[firebase] getFirebaseConfigFromCompany')
        // let appKey = $store.getters['company/google_firebase_app_key']

        // let isValid = validate(...)

        // if (!isValid)
        //     throw new TypeError('appKey is missing expected fields')

        // return appKey

        // Google app
        return firebaseAppKey
    }

    async waitFetchUserIfAuthenticated() {
        if (!$store.getters['auth/check'] || $store.getters['auth/token_type'] != 'FIREBASE')
            return

        $console.log('[firebase] waitFetchUserIfAuthenticated()')

        let waitPromise = () => new Promise((resolve, reject) => {
            this.waitPromiseReject  = reject
            this.waitPromiseResolve = resolve

            setTimeout(() => {
                typeof this.waitPromiseReject === 'function' && this.waitPromiseReject(new Error('Timeout'))
            }, process.env.VUE_APP_API_TIMEOUT)
        })

        await waitPromise()
    }

}