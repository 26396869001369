import VueGtm from '@gtm-support/vue2-gtm'
import router from '../router'
import Vue from 'vue'

let vueGtmConfig = {
    // Este é um id inválido e somente serve para passar a validação do gtm-support
    // O valor real do id virá de CompanySettings e será atribuído a instância de gtm antes do mesmo ser ativado
    id: 'GTM-XXXX',
    enabled: false,
    vueRouter: router,
}

Vue.use(VueGtm, vueGtmConfig)

export default vueGtmConfig