var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app-bar",
    { attrs: { app: "", color: "navbar", dark: "", flat: "", height: "70px" } },
    [
      _vm.$vuetify.breakpoint.smAndDown &&
      _vm.isAuthenticated &&
      _vm.user &&
      _vm.user.hasCompleteBaseInformations()
        ? _c("v-app-bar-nav-icon", {
            staticClass: "ml-md-0 primary--text",
            on: { click: _vm.toggleDrawer }
          })
        : _vm._e(),
      _vm.$vuetify.breakpoint.smAndDown && !_vm.isSimpleLogout
        ? _c("v-spacer")
        : _vm._e(),
      _c(
        "v-toolbar-title",
        {
          staticClass: "mr-0 ml-md-6 pointer clickable",
          on: { click: _vm.titleClick }
        },
        [
          _vm.logo_url
            ? _c("v-img", {
                staticClass: "mx-0",
                attrs: {
                  src: _vm.logo_url,
                  contain: "",
                  "max-width": "160",
                  "max-height": "56"
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c("v-spacer"),
      !_vm.hideCashback && _vm.$vuetify.breakpoint.smAndUp
        ? _c(
            "v-chip",
            {
              staticClass:
                "secondary_font--text font-weight-medium mr-4 body-2",
              attrs: { color: "primary", outlined: "" }
            },
            [
              _c("span", { staticClass: "primary--text" }, [
                _vm._v(_vm._s(_vm.$t("drawer.balance")))
              ]),
              _c(
                "span",
                { staticClass: "primary--text ml-1" },
                [_c("Balance", { attrs: { showPoints: false } })],
                1
              )
            ]
          )
        : _vm._e(),
      !_vm.isAuthenticated
        ? [
            _c(
              "v-btn",
              {
                attrs: { text: "", href: _vm.landingPageUrl },
                on: { click: _vm.welcome }
              },
              [_c("span", [_vm._v(_vm._s(_vm.$t("navbar.welcome")))])]
            ),
            _c(
              "v-btn",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.shouldShowLoginButton,
                    expression: "shouldShowLoginButton"
                  }
                ],
                staticClass: "ml-3",
                attrs: {
                  to: { name: "login" },
                  color: "success",
                  depressed: ""
                }
              },
              [_c("span", [_vm._v(_vm._s(_vm.$t("navbar.login")))])]
            )
          ]
        : _vm.isSimpleLogout
        ? [
            _c(
              "v-btn",
              { attrs: { text: "", color: "black", to: { name: "logout" } } },
              [_c("span", [_vm._v(_vm._s(_vm.$t("navbar.logout")))])]
            )
          ]
        : [
            _c("NotificationIcon"),
            _vm.$vuetify.breakpoint.mdAndUp
              ? _c(
                  "v-menu",
                  {
                    attrs: { "offset-y": "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            return [
                              _c(
                                "v-btn",
                                _vm._g({ attrs: { text: "" } }, on),
                                [
                                  _vm.user
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            "mr-2 secondary_font--text limit-user-name text-capitalize"
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("removeMiddle")(
                                                _vm.user.name
                                              )
                                            )
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "ml-2 secondary_font--text",
                                      attrs: { small: "" }
                                    },
                                    [_vm._v("fas fa-angle-down")]
                                  )
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      3890974374
                    )
                  },
                  [
                    _c(
                      "v-list",
                      [
                        _vm.user && _vm.user.hasCompleteBaseInformations()
                          ? _c(
                              "v-list-item",
                              { attrs: { to: { name: "settings" } } },
                              [
                                _c("v-list-item-title", [
                                  _vm._v(_vm._s(_vm.$t("navbar.settings")))
                                ])
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "v-list-item",
                          { attrs: { to: { name: "logout" } } },
                          [
                            _c("v-list-item-title", [
                              _vm._v(_vm._s(_vm.$t("navbar.logout")))
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e()
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }