<template>
    <v-banner type="info" transition="slide-y-transition"
        :value="shouldShowAuthorizationBanner"
        sticky
        class="banner-intention"
    >
        <v-avatar
            slot="icon"
            size="40"
        >
            <v-icon :color="dynamicIconColor">
                {{ dynamicIcon }}
            </v-icon>
        </v-avatar>

        <span class="font-weight-bold">{{ dynamicLabel }}</span>

        <template v-slot:actions>
            <div v-if="!loading">
                <v-btn
                    v-if="intention && !error"
                    text @click="viewAuthorization"
                    color="primary"
                    class="mb-2"
                >{{ $t('banners.pending_authorization.view') }}</v-btn>
                <v-btn
                    v-else
                    text @click="fetchData"
                    color="primary"
                    class="mb-2"
                >
                    <v-icon>fas fa-redo</v-icon>
                </v-btn>
            </div>
            <div v-else class="text-center mb-3 mr-2">
                {{ $t('globals.loading') }}
            </div>
        </template>
    </v-banner>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    name: 'AuthorizationBanner',
    data: vm => {
        return {
            error    : false,
            loading  : true,
        }
    },
    created() {
        this.fetchData()
    },
    methods: {
        async fetchData() {
            this.loading = true
            this.error = false

            if (this.$store.getters['auth/check'])
                await this.$store.dispatch('authorization/getAuthorization')
                    .catch(e => {
                        this.error = true
                    })

            this.loading = false
        },
        viewAuthorization() {
            this.$router.push({ name: 'authorization', params: { intention: this.intention } })
        },
    },
    computed: {
        ...mapGetters({
            hasAuthorization: 'authorization/hasAuthorization',
            isAuthenticated : 'auth/check',
            intention       : 'authorization/authorization',
        }),
        shouldShowAuthorizationBanner() {
            let ignoreNames = [ 'authorization', 'complete-profile' ]
            return this.isAuthenticated && this.hasAuthorization && !ignoreNames.some(v => v == this.$route.name)
        },

        dynamicIcon() {
            return this.loading ? 'fas fa-spinner fa-spin' : this.error ? 'fas fa-exclamation-triangle' : '$cp_alert_octagon'
        },
        dynamicIconColor() {
            return this.loading ? 'primary' : this.error ? 'error' : 'yellow darken-2'
        },
        dynamicLabel() {
            return this.loading ? this.$t('globals.loading') : this.error ? this.$t('banners.pending_authorization.error') : this.$t('banners.pending_authorization.label')
        },
    },
}
</script>

<style>
.banner-intention {
    border-radius: .5rem !important;
    border: 1px solid var(--v-primary-base) !important;
}
</style>

