export default {
    LoginForm: {
        welcome: {
            sign_in: 'Entrar',
            welcome: 'Bem vindo de volta!',
        },
        identifier: {
            label: 'Usuário',
            hint: 'O seu usuário é seu CPF ou email',
            placeholder: 'Digite o seu CPF ou email',
        },
        password: {
            label: 'Senha',
            hint: '',
            placeholder: 'Digite a sua senha',
        },
        forgot_password: 'Esqueceu a senha?',
        use_social_login_instead: 'Você utilizou uma rede social vinculada a este e-mail para se cadastrar, portanto utilize a mesma rede social para entrar na sua conta.',
    },
    RegisterForm: {
        welcome: {
            sign_in: 'Cadastro',
            welcome: 'Para se cadastrar em {program_name}, preencha os campos abaixo.',
        },
        name: {
            label: 'Nome',
            hint: 'Digite o seu nome',
            placeholder: 'Como deseja ser chamado?',
        },
        email: {
            label: 'Email',
            hint: 'Digite o seu email',
            placeholder: 'exemple@email.com',
        },
        password: {
            label: 'Senha',
            hint: 'Digite a sua senha',
            placeholder: '******',
        },
        // password_confirmation: {
        //     label: 'Confirmação da Senha',
        //     hint: 'Digite a sua senha novamente',
        //     placeholder: '******',
        // },
        document: {
            label: {
                cpf: '@:forms.CustomerForm.document.label.cpf',
                cnpj: '@:forms.CustomerForm.document.label.cnpj'
            },
            hint: {
                cpf: '@:forms.CustomerForm.document.hint.cpf',
                cnpj: '@:forms.CustomerForm.document.hint.cnpj'
            },
            placeholder: {
                cpf: '@:forms.CustomerForm.document.placeholder.cpf',
                cnpj: '@:forms.CustomerForm.document.placeholder.cnpj' 
            }
        },
        opt_in: {
            label: 'Concordo com os {terms} e estou ciente da {privacy}',
            terms: 'Termos de Uso',
            privacy_policy: 'Política de Privacidade',
        },
        opt_in_marketing: {
            label: 'Aceito receber conteúdos publicitários ou comunicados diversos de {companyName}, via meios de contato por mim inseridos ativamente, tais como e-mail e celular',
        },
    },
    // Talvez possa apontar para register, mas é preciso definir se ele ainda vai existir, então por enquanto fica tudo aqui tbm
    CustomerForm: {
        birthdate: {
            label: 'Data de nascimento',
            hint: 'Insira sua data de nascimento',
            placeholder: '01/01/2001',
        },
        document: {
            label: {
                cpf: 'CPF',
                cnpj: 'CNPJ'
            },
            hint: {
                cpf: '',
                cnpj: '',
            },
            placeholder: {
                cpf: '123.123.123-12',
                cnpj: '12.312.312/3123-12'
            },
        },
        address: {
            label: 'Endereço',
            hint: 'Digite seu endereço',
            placeholder: 'R. Cel Dulcídio, 588 - Batel',
        },
        opt_in_marketing: {
            label: '@:forms.RegisterForm.opt_in_marketing.label',
        },
    },
    ProfileCustomer: {
        fullName: {
            label: 'Nome completo',
            hint: '',
            placeholder: 'Como deseja ser chamado?',
        },
        email: {
            label: 'Email',
            hint: 'Habilitado somente para login via email',
            placeholder: 'exemple@email.com',
            required: 'Você precisa informar um email'
        },
        document: {
            label: {
                cpf: '@:forms.CustomerForm.document.label.cpf',
                cnpj: '@:forms.CustomerForm.document.label.cnpj'
            },
            hint: {
                cpf: '@:forms.CustomerForm.document.hint.cpf',
                cnpj: '@:forms.CustomerForm.document.hint.cnpj'
            },
            placeholder: {
                cpf: '@:forms.CustomerForm.document.placeholder.cpf',
                cnpj: '@:forms.CustomerForm.document.placeholder.cnpj' 
            }
        },
        birthdate: {
            label: '@:forms.CustomerForm.birthdate.label',
            hint: '@:forms.CustomerForm.birthdate.hint',
            placeholder: '@:forms.CustomerForm.birthdate.placeholder',
        },
        address: {
            label: '@:forms.CustomerForm.address.label',
            hint: '@:forms.CustomerForm.address.hint',
            placeholder: '@:forms.CustomerForm.address.placeholder',
        },
        phone: {
            label: 'Celular (Opcional)',
            hint: '',
            placeholder: '(00) 00000-0000',
            required: 'Você precisa informar um número'
        },
        password: {
            label: 'Nova senha',
            placeholder: '******',
            hint: 'Digite a sua nova senha',
        },
        password_confirmation: {
            label: 'Confirmação da senha',
            placeholder: '******',
            hint: 'Digite novamente a senha',
            mismatch: 'As senhas devem ser iguais',
        },
        current_password: {
            label: 'Senha atual',
            hint: 'Verifique se o CAPS LOCK está habilitado',
            placeholder: 'Digite sua senha atual',
        },
        gender: {
            label: 'Gênero (Opcional)',
            hint: 'Feminino, masculino, outros...',
            placeholder: 'Escolha seu gênero',
        }
    },
    CompleteForm: {
        name: {
            label: 'Nome',
            hint: '',
            placeholder: '@:forms.ProfileCustomer.fullName.placeholder',
        },
        birthdate: {
            label: '@:forms.CustomerForm.birthdate.label',
            hint: '@:forms.CustomerForm.birthdate.hint',
            placeholder: '@:forms.CustomerForm.birthdate.placeholder',
        },
        document: {
            label: {
                cpf: '@:forms.CustomerForm.document.label.cpf',
                cnpj: '@:forms.CustomerForm.document.label.cnpj'
            },
            hint: {
                cpf: '@:forms.CustomerForm.document.hint.cpf',
                cnpj: '@:forms.CustomerForm.document.hint.cnpj'
            },
            placeholder: {
                cpf: '@:forms.CustomerForm.document.placeholder.cpf',
                cnpj: '@:forms.CustomerForm.document.placeholder.cnpj' 
            }
        },
        address: {
            label: '@:forms.CustomerForm.address.label',
            hint: '@:forms.CustomerForm.address.hint',
            placeholder: '@:forms.CustomerForm.address.placeholder',
        },
        email: {
            label: '@:forms.ProfileCustomer.email.label',
            hint: '@:forms.ProfileCustomer.email.hint',
            placeholder: '@:forms.ProfileCustomer.email.placeholder',
            required: '@:forms.ProfileCustomer.email.required'
        },
        phone: {
            label: '@:forms.ProfileCustomer.phone.label',
            hint: '',
            placeholder: '@:forms.ProfileCustomer.phone.placeholder',
        },
        password: {
            label: 'Nova senha (optativo)',
            label_required: 'Senha',
            hint: 'Verifique se o CAPS LOCK está habilitado',
            placeholder: 'Digite sua nova senha',
            required: 'É obrigatório escolher uma senha'
        },
        password_confirmation: {
            label: 'Confirmação da nova senha (optativo)',
            label_required: 'Confirmação de senha',
            hint: '@:forms.ProfileCustomer.password.hint',
            placeholder: 'Digite a sua nova senha novamente',
            mismatch: 'As senhas devem ser iguais',
        },
        current_password: {
            label: '@:forms.ProfileCustomer.current_password.label',
            hint: '@:forms.ProfileCustomer.password.hint',
            placeholder: '@:forms.ProfileCustomer.current_password.placeholder',
        },
        errors: {
            age_restriction_from: 'O cadastro é permitido apenas para pessoas a partir de {from} anos de idade.',
            age_restriction_to: 'O cadastro é permitido apenas para pessoas com até {to} anos de idade.',
        },
        opt_in: {
            label: '@:forms.RegisterForm.opt_in.label',
            terms: '@:forms.RegisterForm.opt_in.terms',
            privacy_policy: '@:forms.RegisterForm.opt_in.privacy_policy',
            required_error: '@:views.RegisterView.opt_in_error'
        },
        gender: {
            label: '@:forms.ProfileCustomer.gender.label',
            hint: '@:forms.ProfileCustomer.gender.hint',
            placeholder: '@:forms.ProfileCustomer.gender.placeholder',
        }
    },
    ForgotPasswordForm: {
        description: 'Insira no campo abaixo o usuário utilizado para acessar o painel. Enviaremos uma mensagem para o email desse usuário com instruções para a redefinição de senha.',
        success: 'Se o usuário existir, acabamos de enviar uma mensagem contendo instruções sobre como redefinir a senha para seu email. Verifique a sua caixa de entrada.',

        identifier: {
            label: '@:forms.LoginForm.identifier.label',
            placeholder: '@:forms.LoginForm.identifier.placeholder',
            hint: '@:forms.LoginForm.identifier.hint',
        },
    },
    ResetPassword: {
        title: 'Alterar senha',
        loading: 'Carregando...',
        different_password: 'A confirmação da senha não coincide com a senha. Verifique novamente.',
        success: 'A sua senha foi redefinida.',
        token_expired: 'O link de redefinição de senha expirou. Tente novamente.',
        update: '@:actions.update',
        cancel: '@:actions.cancel',
        forgot_password: 'Esqueceu a senha?',
        confirmation_modal: 'Ao confirmar você será deslogado e redirecionado para "esqueci minha senha".',

        current_password: {
            label: '@:forms.ProfileCustomer.current_password.label',
            hint: '@:forms.ProfileCustomer.password.hint',
            placeholder: '@:forms.ProfileCustomer.current_password.placeholder',
            mismatch: 'A senha atual é obrigatória',
        },
        password: {
            label: 'Nova senha',
            placeholder: '******',
            hint: 'Digite a sua nova senha',
        },
        password_confirmation: {
            label: 'Confirmação da nova senha',
            placeholder: '******',
            hint: 'Digite novamente a sua nova senha',
            mismatch: 'As senhas devem ser iguais',
        },
    },
}