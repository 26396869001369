var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-banner",
    {
      staticClass: "banner-intention",
      attrs: {
        type: "info",
        transition: "slide-y-transition",
        value: _vm.shouldShowAuthorizationBanner,
        sticky: ""
      },
      scopedSlots: _vm._u([
        {
          key: "actions",
          fn: function() {
            return [
              !_vm.loading
                ? _c(
                    "div",
                    [
                      _vm.intention && !_vm.error
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "mb-2",
                              attrs: { text: "", color: "primary" },
                              on: { click: _vm.viewAuthorization }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("banners.pending_authorization.view")
                                )
                              )
                            ]
                          )
                        : _c(
                            "v-btn",
                            {
                              staticClass: "mb-2",
                              attrs: { text: "", color: "primary" },
                              on: { click: _vm.fetchData }
                            },
                            [_c("v-icon", [_vm._v("fas fa-redo")])],
                            1
                          )
                    ],
                    1
                  )
                : _c("div", { staticClass: "text-center mb-3 mr-2" }, [
                    _vm._v(" " + _vm._s(_vm.$t("globals.loading")) + " ")
                  ])
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "v-avatar",
        { attrs: { slot: "icon", size: "40" }, slot: "icon" },
        [
          _c("v-icon", { attrs: { color: _vm.dynamicIconColor } }, [
            _vm._v(" " + _vm._s(_vm.dynamicIcon) + " ")
          ])
        ],
        1
      ),
      _c("span", { staticClass: "font-weight-bold" }, [
        _vm._v(_vm._s(_vm.dynamicLabel))
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }