<template>
    <v-navigation-drawer
        :permanent="$vuetify.breakpoint.mdAndUp"
        :mini-variant="$vuetify.breakpoint.mdAndUp && mini"
        :app="$vuetify.breakpoint.smAndDown"
        :absolute="$vuetify.breakpoint.mdAndUp"
        :value="value" @input="onInput" disable-resize-watcher
        :hide-overlay="mini" @click="mini = !mini"
    >
        <v-list-item>
            <v-list-item-content>
                <v-row>
                    <v-col v-if="$vuetify.breakpoint.smAndDown || !mini" cols="10">
                        <v-list-item-title class="title">
                            {{ programName }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                            {{ $t('drawer.subtitle') }}
                        </v-list-item-subtitle>

                        <v-row 
                            class="mx-0 caption secondary_font--text mt-1"
                            v-if="$vuetify.breakpoint.xs"
                        >
                            <div class="mr-1">{{ $t('drawer.balance') }}</div>
                            <Balance :showPoints="false" />
                        </v-row>
                    </v-col>

                    <v-col cols="2" v-if="$vuetify.breakpoint.mdAndUp && !mini">
                        <v-icon @click="mini = true" color="primary" class="px-2 py-1 mt-2 mr-1 close-drawer-icon">
                            fas fa-angle-left
                        </v-icon>
                    </v-col>

                    <v-col cols="12" v-if="$vuetify.breakpoint.mdAndUp && mini" class="pt-2 pb-5">
                        <v-icon @click="mini = false" color="primary">
                            fas fa-bars
                        </v-icon>
                    </v-col>
                </v-row>
            </v-list-item-content>
        </v-list-item>

        <v-list
            class="drawer-list"
            dense
            nav
        >
            <template v-if="isAuthenticated">
                <v-list-item link
                    v-for="item in items"
                    :key="item.title"
                    :to="item.to"
                    :disabled="item.disabled"
                    :hidden="item.hidden"
                    @click="mini = true"
                >
                    <v-list-item-icon class="d-flex justify-center">
                        <v-icon :size="20" color="primary" class="list-icon">{{ item.icon }}</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title class="align-right">{{ item.title }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </template>

            <template v-else>
                <v-list-item link :to="{ name: 'login' }">
                    <v-list-item-icon>
                        <v-icon>fas fa-sign-in-alt</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{ $t('actions.login') }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item link :to="{ name: 'login' }">
                    <v-list-item-icon>
                        <v-icon>fas fa-user</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{ $t('actions.register') }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item link @click="welcome">
                    <v-list-item-icon>
                        <v-icon>fas fa-info-circle</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{ $t('drawer.welcome') }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </template>
        </v-list>


        <v-list-item v-if="!isAuthenticated">
            <v-list-item-content>
                <v-list-item-subtitle>
                    <p>{{ $t('drawer.auth_needed') }}</p>
                </v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>
    </v-navigation-drawer>
</template>

<script>
import { mapGetters } from 'vuex'
import Balance from '@/components/structure/balance/Balance.vue'

export default {
    name: 'Drawer',
    components: { Balance },
    props: {
        value: Boolean,
    },
    data: vm => ({
        configError: false,
        mini: true,
    }),
    methods: {
        onInput(value) {
            this.$emit('input', value)
        },
        welcome() {
            if (!this.landingPageUrl) {
                this.configError = true
                return
            }

            this.$emit('input', false)
            window.location = this.landingPageUrl
        },
    },
    computed: {
        ...mapGetters({
            programName    : 'company/program_name',
            landingPageUrl : 'company/landing_page_domain',
            isAuthenticated: 'auth/check',
            hasLevels      : 'levels/hasLevels',
            faq            : 'company/faq',
            integrations   : 'company/integrations',
        }),
        verifyFaqLength() {
            let isEmpetyArry = Array.isArray(this.faq.items) ? this.faq.items
                .filter(item => !this.$lodash.isEmpty(item)) : []

            return isEmpetyArry.length ? true : false
        },

        hasSubscriptionIntegration() {
            const url = this.$lodash.get(this.integrations, 'subscription.frontend_url')
            return !this.$lodash.isNil(url)
        },

        items() {
            let items = [
                { title: this.$t('drawer.dashboard'),    hidden: false,                            disabled: !this.isAuthenticated, to: { name: 'home' },         icon: '$cp_home'          },
                { title: this.$t('drawer.levels'),       hidden: !this.hasLevels,                  disabled: !this.isAuthenticated, to: { name: 'levels' },       icon: '$cp_star'          },
                { title: this.$t('drawer.history'),      hidden: false,                            disabled: !this.isAuthenticated, to: { name: 'history' },      icon: '$cp_list'          },
                { title: this.$t('drawer.prizes'),       hidden: false,                            disabled: !this.isAuthenticated, to: { name: 'prizes' },       icon: '$cp_bag_shopping'  },
                { title: this.$t('drawer.promotion'),    hidden: false,                            disabled: !this.isAuthenticated, to: { name: 'promotion' },    icon: '$cp_promotion'     },
                { title: this.$t('drawer.settings'),     hidden: false,                            disabled: !this.isAuthenticated, to: { name: 'settings' },     icon: '$cp_user'          },
                { title: this.$t('drawer.faq'),          hidden: !this.verifyFaqLength,            disabled: !this.isAuthenticated, to: { name: 'faq' },          icon: '$cp_help_circle'   },
                { title: this.$t('drawer.subscription'), hidden: !this.hasSubscriptionIntegration, disabled: !this.isAuthenticated, to: { name: 'subscription' }, icon: '$cp_box'           },
            ]

            if (this.isAuthenticated)
                items.push({ title: this.$t('drawer.logout'), disabled: !this.isAuthenticated, to: { name: 'logout' }, icon: '$cp_logout' })

            return items
        },
    },
}
</script>

<style lang="scss">
.close-drawer-icon {
    float: right;
}

.list-icon {
    width: 30px;
    opacity: 0.3;
}

.v-list-item--active .list-icon {
    opacity: 1;
}

.v-list--nav .v-list-item:before {
    border-radius: 8px;
}
</style>