const mapping = {
    // Paginação
    PAGINATION_MAX_PER_PAGE: 5000,

    // Sistemas de níveis disponíveis
    LOOKBACK_LEVELS_SYSTEM  : 'LOOKBACK',
    RENOVATION_LEVELS_SYSTEM: 'RENOVATION',

    // Headers
    'CUSTOMER_DOCUMENT_HEADER_KEY': 'customer-document',
    'CUSTOMER_EMAIL_HEADER_KEY'   : 'customer-email',

    // Tipos de promoções
    'PROMOTION_FILTER_TYPE_NEW_REGISTER'     : 'NEW_REGISTER',
    'PROMOTION_FILTER_TYPE_ACTIVATIONS_LIMIT': 'ACTIVATIONS_LIMIT',
    'PROMOTION_FILTER_TYPE_COMPLETE_PROFILE' : 'COMPLETE_PROFILE',
    'PROMOTION_ACTION_TYPE_GENERATE_POINTS'  : 'GENERATE_POINTS',
    'PROMOTION_TYPE_BANNER_CLICK'            : 'BANNER_CLICK',

    // Modos de exibição de pontos
    'DISPLAY_TYPE_POINTS'    : 'POINTS',
    'DISPLAY_TYPE_CASHBACK'  : 'CASH',
    'DISPLAY_TYPE_PERCENTAGE': 'PERCENTAGE',

    // Origens possíveis para gerações
    'API_POINTS_GENERATE_GENERATOR_ORIGIN': 'api:/points/generate',
    'PROMOTION_GENERATOR_ORIGIN'          : 'promotion',

    // Configs de expiração de cookies
    'COOKIE_EXPIRES_IN_YEARS_AUTHENTICATED': 3,   // 3 anos
    'COOKIE_EXPIRES_IN_YEARS_GUEST'        : 1,   // 1 ano

    // Tipos de gêneros
    'GENDER_OPTION_MALE'      : 'M',
    'GENDER_OPTION_FEMALE'    : 'F',
    'GENDER_OPTION_OTHERS'    : 'O',
    'GENDER_OPTION_NOT_ANSWER': 'N',

    // Tipos de login disponíveis para o customer
    'CUSTOMER_LOGIN_TYPE_PRE'     : 'PRE',
    'CUSTOMER_LOGIN_TYPE_EMAIL'   : 'EMAIL',
    'CUSTOMER_LOGIN_TYPE_GOOGLE'  : 'GOOGLE',
    'CUSTOMER_LOGIN_TYPE_PHONE'   : 'PHONE',
    'CUSTOMER_LOGIN_TYPE_FACEBOOK': 'FACEBOOK',
}

export default new class ConstantsService {

    /**
     * Retorna o valor de uma constante
     *
     * @param {string} key chave da constante
     */
    getConstant(key) {
        let value = mapping[key]

        if (!value)
            throw new Error(`Constant ${key} is not defined.`)

        return value
    }

}