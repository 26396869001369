import $console from '@/services/console'
import $store   from '@/store'

export default new class MiddlewareIndex {

    constructor() {
        this._middlewares = []
        this.loadMiddlewares()
    }

    /**
     * Carrega todos os middlewares da pasta nesta classe
     */
    loadMiddlewares() {
        const files       = require.context('.', false, /\.js$/)
        var   middlewares = []

        files.keys().forEach(key => {
            // Ignora este proprio arquivo
            if (key === './index.js' || key === './BaseMiddleware.js') return
            middlewares.push(new (files(key).default))
        })

        this._middlewares = middlewares
    }

    /**
     * Passa os middlewares em um router
     *
     * @param {VueRouter} router instância do router
     */
    async setup(router) {
        router.beforeEach(this.beforeEach.bind(this))
    }

    async beforeEach(to, from, next) {
        // [LOG]
        $console.log('[router] ', from.name, to.name, to.query)

        // Verifica se os serviços já foram carregados [FID-542]
        // obs. tem que vir antes da execução dos middlewares
        let isAppLoaded = $store.getters['app/isLoaded']

        if (!isAppLoaded) {
            $console.log('[router] app isn\'t loaded yet')
            if (to.name == 'loading') {
                next()
            } else {
                let redirect = to.path + '?' + (new URLSearchParams(to.query).toString())
                next({ name: 'loading', query: { redirect } })
            }
            return
        }

        let promises   = this._middlewares.map(async (middleware) => !(await middleware.shouldRun(to, from)) || await middleware.handle(to, from, next) )
        let shouldNext = !(await Promise.all(promises)).some(a => !a)
        if (shouldNext)
            next()
        else
            $console.log('[router] cancel')
    }
}