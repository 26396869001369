import $store from '@/store'
import errorHandler from '@/utils/errorHandler'

export default [
    {
        path: '/fidelidade',
        name: 'home',
        component: () => import(/* webpackChunkName: "home" */ '@/views/home/HomeView.vue'),
        redirect: '/fidelidade/resumo',
        children: [
            {
                path: 'resumo',
                name: 'home.dashboard',
                component: () => import(/* webpackChunkName: "home" */ '@/views/home/DashboardView.vue'),
                meta: {
                    mustHaveCompleteProfile: true,
                    requiresAuthentication : true,
                    title                  : 'home',
                },
            },
        ],
    },
]