import axiosInstance  from '@/services/axios/index'
import Level          from '@/models/Level'

// Initial state
const state = {
    levels   : [],
}

// Getters
const getters = {
    levels      : state => state.levels,
    hasLevels   : state => !!state.levels && !!state.levels.length, 
    getLevelById: state => (id) => state.levels.find(l => l.id == id),
}

// Mutations
const mutations = {
    SET_LEVELS(state, data = []) {
        state.levels = data
    },
}

// Actions
const actions = {
    async fetchData({ commit }) {
        let response = await axiosInstance({
            url: 'level',
            method: 'get',
        })

        if (!response || !response.data)
            return

        let levels = []
        response.data.forEach(level => {
            levels.push(new Level(level))
        })

        commit('SET_LEVELS', levels)
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
 