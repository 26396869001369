import SharedPrize from '@/shared/models/Prize'
import store from '@/store'

export default class Prize extends SharedPrize {

    async buy() {
        let prizeData = this.getData([ 'id' ])

        let data = {
            customer_document: store.getters['auth/user'].document,
            products: [
                {
                    prize_id: prizeData.id,
                    quantity: 1,
                },
            ],
        }

        let response = await this.request({
            url   : `/voucher/generate`,
            method: 'post',
            data,
        })

        if (!response)
            return

        return response
    }

    static async count(additionalParams = {}) {
        let response = await this.request({
            url   : '/prize/count',
            method: 'get',
            params: {
                ...additionalParams,
            },
        })

        if (!response)
            return

        return response.data
    }
}