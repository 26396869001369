<template>
   <svg xmlns="http://www.w3.org/2000/svg" width="30.497" height="30.5" viewBox="0 0 30.497 30.5"><g transform="translate(0 0)"><path class="a" d="M29.124,15.25l1.338-3.49a.954.954,0,0,0-.29-1.083l-2.9-2.352-.587-3.692a.953.953,0,0,0-.793-.791L22.2,3.255,19.85.352a.955.955,0,0,0-1.081-.29L15.277,1.4,11.787.065a.952.952,0,0,0-1.081.292l-2.352,2.9-3.69.587a.953.953,0,0,0-.793.791L3.284,8.327l-2.9,2.352A.951.951,0,0,0,.092,11.76L1.43,15.25.092,18.74a.951.951,0,0,0,.292,1.081l2.9,2.352.587,3.692a.953.953,0,0,0,.793.791l3.692.587,2.35,2.9a.949.949,0,0,0,1.081.29l3.49-1.338,3.49,1.338a.954.954,0,0,0,1.083-.292l2.35-2.9,3.692-.587a.953.953,0,0,0,.793-.791l.587-3.692,2.9-2.352a.954.954,0,0,0,.29-1.083Zm-1.908.339,1.222,3.183-2.649,2.146a.952.952,0,0,0-.343.591l-.534,3.368-3.37.536a.959.959,0,0,0-.593.341L18.8,28.4l-3.185-1.222a1.1,1.1,0,0,0-.341-.059.957.957,0,0,0-.341.063l-3.185,1.222L9.6,25.758a.949.949,0,0,0-.593-.341l-3.37-.536-.534-3.368a.952.952,0,0,0-.343-.591L2.12,18.776l1.222-3.183a.972.972,0,0,0,0-.684L2.12,11.724,4.769,9.578a.952.952,0,0,0,.343-.591l.534-3.368,3.37-.536a.959.959,0,0,0,.593-.341l2.146-2.649L14.94,3.314a.952.952,0,0,0,.68,0L18.8,2.093l2.146,2.649a.949.949,0,0,0,.593.341l3.37.536.534,3.368a.952.952,0,0,0,.343.591l2.649,2.146-1.222,3.183A.957.957,0,0,0,27.216,15.589Z" transform="translate(-0.029 0)"/></g><g transform="translate(11.436 7.626)"><path class="a" d="M195.838,134.706c-1.033,0-1.906-.654-1.906-1.43s.873-1.43,1.906-1.43a2.153,2.153,0,0,1,1.363.454.952.952,0,1,0,1.2-1.477,3.9,3.9,0,0,0-1.612-.762v-1.073a.953.953,0,0,0-1.906,0v1.071a3.451,3.451,0,0,0-2.859,3.217,3.6,3.6,0,0,0,3.812,3.336c1.033,0,1.906.654,1.906,1.43s-.873,1.43-1.906,1.43a2.153,2.153,0,0,1-1.363-.454.952.952,0,0,0-1.2,1.477,3.917,3.917,0,0,0,1.612.76v1.075a.953.953,0,0,0,1.906,0v-1.071a3.451,3.451,0,0,0,2.859-3.217A3.6,3.6,0,0,0,195.838,134.706Z" transform="translate(-192.026 -128.035)"/></g></svg>
</template>

<script>
export default {
    name: 'BalanceIcon',
}
</script>

<style scoped lang="scss">
.a {
    fill: currentColor;
}
</style>