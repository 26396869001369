export default [
    {
        path: '/entrar',
        props: true,
        component: () => import(/* webpackChunkName: "auth" */ '@/views/auth/Base.vue'),
        children: [
            // {
            //     path: 'cadastro',
            //     name: 'register',
            //     component: () => import(/* webpackChunkName: "auth" */ '@/views/auth/FirebaseAuthView.vue'),
            //     meta: {
            //         guestOnly: true,
            //         title: 'register',
            //     },
            // },
            {
                path: '',
                name: 'login',
                props: true,
                component: () => import(/* webpackChunkName: "auth" */ '@/views/auth/FirebaseAuthView.vue'),
                meta: {
                    guestOnly: true,
                    title: 'login',
                    noNavbar: true,
                },
            },
            {
                path: '/entrar-link',
                name: 'auth.link_login',
                props: true,
                component: () => import(/* webpackChunkName: "auth" */ '@/views/auth/LinkLogin.vue'),
                meta: {
                    guestOnly: true,
                    title: 'login',
                    noNavbar: true,
                },
            },
            {
                path: '/entrar-email',
                name: 'auth.email_login',
                props: true,
                component: () => import(/* webpackChunkName: "auth.email_login" */ '@/views/auth/EmailLogin.vue'),
                meta: {
                    guestOnly: true,
                    title: 'login',
                    noNavbar: true,
                },
            },
            {
                path: '/esqueci-minha-senha',
                name: 'auth.forgot_password',
                props: true,
                component: () => import(/* webpackChunkName: "auth.forgot_password" */ '@/views/auth/ForgotPassword.vue'),
                meta: {
                    guestOnly: true,
                    title: 'login',
                    noNavbar: true,
                },
            },
            {
                name: 'auth.reset_password',
                path: '/redefinir-senha',
                component: () => import(/* webpackChunkName: "auth.reset_password" */ '@/views/auth/ResetPassword.vue'),
                props: true,
                meta: {
                    guestOnly: true,
                    noNavbar: true,
                },
            },
            {
                name: 'register',
                path: '/cadastrar-email',
                component: () => import(/* webpackChunkName: "register" */ '@/views/auth/Register.vue'),
                props: true,
                meta: {
                    guestOnly: true,
                    noNavbar: true,
                },
            },
        ],
    },
    {
        path: '/sair',
        name: 'logout',
        component: () => import(/* webpackChunkName: "auth.logout" */ '@/views/auth/LogoutView.vue'),
        meta: {
            requiresAuthentication: true,
            noNavbar: true,
        },
    },
]