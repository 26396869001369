import BaseModel from '@/models/BaseModel'
import Subsidiary from '@/models/Subsidiary'

export default class Intention extends BaseModel {

    defaults() {
        return {
            id           : null,
            value        : null,
            code         : null,
            integrator_id: null,
            amount       : null,
            customer_id  : null,
            type_id      : null,
            external_id  : null,
            source_key   : null,
            expires_at   : null,
            created_at   : null,
        }
    }

    relationships() {
        return {
            company: { class: Subsidiary },
        }
    }

    // Não precisa de validação, pois não havera o cadastro nesta app
}