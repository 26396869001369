import Vue from 'vue'
// Bugsnag precisa ser iniciado antes de todo o resto para poder
// reportar erros que possam ocorrer nos módulos iniciais
import BugsnagService from '@/services/bugsnag'
BugsnagService.setup(Vue)

import App from './App.vue'
import router from './router'
import store from './store'
import gtm from './plugins/gtm'
import vuetify from './plugins/vuetify'
import services from './services'
import './utils/filters'
import i18n from './i18n'
import './assets/scss/index.scss'
import shared from './shared'

Vue.config.productionTip = false

Vue.use(services)
Vue.use(shared)

new Vue({
  router,
  store,
  gtm,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
