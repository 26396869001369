export default [
    {
        path: '/meu-cadastro',
        name: 'settings',
        component: () => import(/* webpackChunkName: "settings" */ '@/views/settings/SettingsView.vue'),
        meta: {
            requiresAuthentication  : true,
            mustHaveCompleteProfile : true,
            title                   : 'settings',
        },
    },
]