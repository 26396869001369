import constants from '@/services/constants'

export default {
    Generator: {
        // Somente mobile
        title_mobile_points       : 'Ganhou {points_amount} pts | {points_value}',
        title_mobile_percentage   : 'Ganhou {points_percentage}% de cashback',
        title_mobile_cash         : 'Ganhou {points_value} de cashback',
        title_mobile_hide_cashback: 'Ganhou {points_amount} pts de cashback',
        title_mobile_hide_points  : 'Realizou uma compra',

        // Somente desktop
        title_hide_points: {
            [`${constants.getConstant('API_POINTS_GENERATE_GENERATOR_ORIGIN')}_origin`]: 'Realizou um compra em <b>{subsidiary_name}</b>',
            [`${constants.getConstant('PROMOTION_GENERATOR_ORIGIN')}_origin`]          : 'Participou de uma de nossas promoções',
            default_origin                                                             : 'Realizou uma compra ou participou de uma de nossas promoções',
        },
        title_hide_cashback: {
            [`${constants.getConstant('API_POINTS_GENERATE_GENERATOR_ORIGIN')}_origin`]: 'Consumiu {value} em <b>{subsidiary_name}</b> e ganhou {points_amount} pts',
            [`${constants.getConstant('PROMOTION_GENERATOR_ORIGIN')}_origin`]          : 'Ganhou {points_amount} pts ao participar de uma de nossas promoções',
            default_origin                                                             : 'Ganhou {points_amount} pts',
        },
        title_points: {
            [`${constants.getConstant('API_POINTS_GENERATE_GENERATOR_ORIGIN')}_origin`]: 'Consumiu {value} em <b>{subsidiary_name}</b> e ganhou {points_amount} pts | {points_value}',
            [`${constants.getConstant('PROMOTION_GENERATOR_ORIGIN')}_origin`]          : 'Ganhou {points_amount} pts | {points_value} ao participar de uma de nossas promoções',
            default_origin                                                             : 'Ganhou {points_amount} pts | {points_value}',
        },
        title_percentage: {
            [`${constants.getConstant('API_POINTS_GENERATE_GENERATOR_ORIGIN')}_origin`]: 'Consumiu {value} em <b>{subsidiary_name}</b> e ganhou {points_percentage}% de cashback',
            [`${constants.getConstant('PROMOTION_GENERATOR_ORIGIN')}_origin`]          : 'Ganhou {points_percentage}% de cashback ao participar de uma de nossas promoções',
            default_origin                                                             : 'Ganhou {points_percentage}% de cashback',
        },
        title_cash: {
            [`${constants.getConstant('API_POINTS_GENERATE_GENERATOR_ORIGIN')}_origin`]: 'Consumiu {value} em <b>{subsidiary_name}</b> e ganhou {points_value} de cashback',
            [`${constants.getConstant('PROMOTION_GENERATOR_ORIGIN')}_origin`]          : 'Ganhou {points_value} de cashback ao participar de uma de nossas promoções',
            default_origin                                                             : 'Ganhou {points_value} de cashback',
        },

        // Comum a desktop e mobile
        title_points_zero         : 'Esta compra não gerou pontos',
        title_percentage_cash_zero: 'Esta compra não gerou cashback',
        subtitle: {
            reversal: 'Esta geração de pontos foi cancelada.',
            reversal_hide_points: 'Esta compra foi cancelada.',
        },
        action: 'Você recebeu',
    },
    Consumption: {
        title: 'Utilizou {points_amount} pts em {subsidiary_name}',
        title_points: 'Utilizou {points_amount} pts em <b>{subsidiary_name}</b> no valor de {points_value}',
        title_percentage_cash: 'Utilizou {points_value} de cashback em <b>{subsidiary_name}</b>',
        title_hide_cashback: 'Utilizou {points_amount} pts em <b>{subsidiary_name}</b>',
        title_hide_points: 'Realizou um consumo em <b>{subsidiary_name}</b>',

        title_voucher: 'Comprou um voucher no valor {points_amount} pts | {points_value} em <b>{subsidiary_name}</b>',
        title_voucher_free: 'Ganhou um voucher em <b>{subsidiary_name}</b>',
        title_voucher_hide_cashback: 'Comprou um voucher no valor {points_amount} pts em <b>{subsidiary_name}</b>',
        title_voucher_hide_points: 'Comprou um voucher em <b>{subsidiary_name}</b>',
        
        title_mobile: 'Utilizou {points_amount} pts em <b>{subsidiary_name}</b>',
        title_mobile_hide_points: 'Realizou um consumo',
        subtitle: {
            reversal: 'Este consumo de pontos foi cancelado.',
            reversal_hide_points: 'Este consumo foi cancelado.',
        },
        action: 'Você trocou',
    },
    Reversal: {
        title: {
            GNR: {
                title_points: 'Cancelamento da geração de {points_amount} pontos no valor de {points_value} em <b>{subsidiary_name}</b>.',
                title_percentage_cash: 'Cancelamento da geração no valor de {points_value} em <b>{subsidiary_name}</b>.',
                title_hide_cashback: 'Cancelamento da geração de {points_amount} pontos em <b>{subsidiary_name}</b>.',
                title_hide_points: 'Cancelamento de compra em <b>{subsidiary_name}</b>.',
            },
            CNS: {
                title_points: 'Cancelamento do consumo de {points_amount} pontos no valor de {points_value} em <b>{subsidiary_name}</b>.',
                title_percentage_cash: 'Cancelamento do consumo no valor de {points_value} em <b>{subsidiary_name}</b>.',
                title_hide_cashback: 'Cancelamento do consumo de {points_amount} pontos em <b>{subsidiary_name}</b>.',
                title_hide_points: 'Cancelamento de consumo em <b>{subsidiary_name}</b>.',
            },
            mobile: 'Cancelamento de {points_value} em <b>{subsidiary_name}</b>.',
            mobile_hide_points: 'Cancelamento de operação em <b>{subsidiary_name}</b>.',
            mobile_hide_cashback: 'Cancelamento de {points_amount} pts em <b>{subsidiary_name}</b>.',
            default: 'Cancelamento.',
        },

        action: {
            GNR: 'Cancelamento',
            CNS: 'Estorno',
        },
    },
    Promotion: {
        range: {
            format: '{start_at} - {end_at}',
            from: 'Ativa desde {start_at}',
            to: 'Ativa até {end_at}',
        },
    },
    PromotionAction: {
        type: {
            GENERATE_POINTS: {
                list_item_label: 'Você ganhou {0}!',
            },
        },
    },
    PromotionFilter: {
        type: {
            NEW_REGISTER: {
                list_item_label: 'Esta promoção é válida somente para cadastros novos.',
            },
            ACTIVATIONS_LIMIT: {
                list_item_label: 'Você já ativou esta promoção.',
            },
            COMPLETE_PROFILE: {
                list_item_label: 'Esta promoção é válida somente para cadastros com perfil completo.',
            },
        },
    },
}