import _ from 'lodash'

/**
 * Analisa um objeto de forma recursiva e só subtitui os campos
 * que não são indefinidos. Utilizado para não sobrescrever os
 * valores padrão do estado do vuex.
 *
 * @param {*}      state estado de um módulo do vuex ou objeto contendo os valores padrão
 * @param {object} data  campos a serem sobrescritos
 */
export default function dataOrDefault(state, data = {}) {
    if (!state)
        throw new TypeError('É obrigatório passar o estado do vuex.')

	if (data === undefined || data === null)
		return

    // Para cada campo definido em data...
    for (let field in data) {
        let value = data[field]
        if (value === undefined)
            continue

        if (_.isPlainObject(value) && state[field]) {
            dataOrDefault(state[field], value)
        } else
            state[field] = value
    }
}