<template>
    <v-app>
        <template v-if="isAppLoaded || inMaintenance">
            <template v-if="isAppLoaded && !inMaintenance && exist && isAvailable">
                <Drawer class="drawer" v-if="isAuthenticated && shouldDrawer" v-model="isDrawerOpen"/>
                <Navbar v-if="shouldShowNavbar" @toggleDrawer="toggleDrawer"/>

                <v-content class="content" :class="contentStyles">
                    <div class="d-flex flex-column fill-height">
                        <GeneralAlert ref="generalAlert" v-if="isAuthenticated"  />
                        <AuthorizationBanner class="intention-banner mt-2"/>
                        <router-view/>
                        <Footer class="mt-auto"/>
                    </div>
                </v-content>
            </template>
            <template v-else>
                <v-row v-if="!inMaintenance && !exist" style="background-color: #FFFFFF">
                    <img height="100%" width="100%" :src="require('@/assets/errors/404.png')" />
                </v-row>

                <v-row v-if="inMaintenance || !isAvailable" style="background-color: #FFFFFF">
                    <img height="100%" width="100%" :src="require('@/assets/errors/maintenance.png')" />
                </v-row>    
            </template>
        </template>
        <template v-else>
            <div class="text-center mt-5">
                {{ $t('globals.loading') }}
            </div>
        </template>

        <!-- Modal do age gate -->
        <AgeGateModal ref="age_gate"/>

        <!-- Agrega todas as notificações importantes -->
        <NotificationFooter/>

        <!-- Modais úteis -->
        <AppSnackbar       ref="snackbar"/>
        <AlertModal        ref="alert" />
        <ConfirmationModal ref="confirmation"/>
    </v-app>
</template>

<script>
import Navbar               from '@/components/structure/Navbar'
import Drawer               from '@/components/structure/Drawer'
import Footer               from '@/components/structure/Footer'
import AppSnackbar          from '@/components/structure/AppSnackbar'
import AlertModal           from '@/components/modals/AlertModal'
import ConfirmationModal    from '@/components/modals/ConfirmationModal'
import AgeGateModal         from '@/components/modals/AgeGateModal'
import AuthorizationBanner  from '@/components/structure/AuthorizationBanner'
import NotificationFooter   from '@/components/structure/NotificationFooter'
import InitServices         from '@/services/setup'
import GeneralAlert         from '@/shared/components/alerts/GeneralAlert'
import { mapGetters }       from 'vuex'
import HasErrorHandlerMixin from '@/mixins/HasErrorHandlerMixin'

export default {
    name: "App",
    components: {
        Navbar,
        Drawer,
        AuthorizationBanner,
        AlertModal,
        ConfirmationModal,
        AgeGateModal,
        NotificationFooter,
        Footer,
        AppSnackbar,
        GeneralAlert
    },
    mixins: [ HasErrorHandlerMixin ],
    data: vm => ({
        inMaintenance: process.env.VUE_APP_MAINTENANCE != '0',
        isDrawerOpen : false,
        exist        : true,
        isAvailable  : true,
    }),
    created() {
        // Inicializa os eventos do bus global
        const self = this

         /**
         * Mostra uma mensagem não bloqueante (snackbar)
         *
         * @param {string} message mensagem a ser mostrada para o usuário
         * @param {string} color   cor da mensagem
         */
        this.$bus.$on('message', (message, color) => {
            self.$refs.snackbar && self.$refs.snackbar.new(message, color)
        })

        /**
         * Mostra um alerta que espera uma interação com o usuário (bloqueante)
         *
         * @param {string}     message  mensagem a ser mostrada para o usuário
         * @param {(string)}   type     success para alerta de sucesso e error para alerta de erro
         * @param {(function)} callback função a ser executada após a interação com o usuário
         * @param {(boolean)}  usehtml  indica se deve permitir a renderização de HTML na mensagem do alerta (cuidado!)
         */
        this.$bus.$on('alert', async (message, type = 'success', callback = e => null, useHtml = false) => {
            let functionMapping = {
                success: self.$refs.alert.showSuccess,
                error  : self.$refs.alert.showError,
            }
            await functionMapping[type](message, useHtml)
                .catch(callback)
            if (typeof callback == 'function')
                callback()
        })

        /**
         * Mostra um alerta de confirmação que espera uma interação com o usuário (bloqueante)
         * e retorna um valor para confirmado ou não
         *
         * @param {string}     message  mensagem a ser mostrada para o usuário
         * @param {(function)} callback função a ser executada após a interação com o usuário
         */
        this.$bus.$on('confirm', async (message, callback = e => null) => {
            let allowed = await self.$refs.confirmation.showConfirmation(message)
            if (typeof callback == 'function')
                callback(allowed)
        })

        /**
         * Mostra uma notificação no topo da pagina, com um texto e um hiperlink.
         * clicando no link, o usuário será redirecionado para uma rota ou link externo.
         *
         * @param {string} message mensagem a ser mostrada para o usuário
         * @param {Object} configurations recebe um objeto com as seguites prorpiedades
         * @param {boolean} configurations.redirect Caso seja TRUE ao clicar no link o 
         * usuário será redirecionado para uma página externar. Caso FALSE, será 
         * redirecionado pra uma rota (padrão é FALSE).
         * @param {string} configurations.action_link Link externo ou nome da rota 
         * a ser redirecionado.
         * @param {Object} configurations.action_router Objeto de rota.
         * @param {Oblect} configurations.localStorageConfig Recebe 'itemKey', 'value' e 'expireIn'
         * @param {string} configurations.action_text Texto do hiperlink.
         */
        this.$bus.$on('generalAlert', (message, configurations = {}) => {
            self.$refs.generalAlert && self.$refs.generalAlert.new(message, configurations)
        })
    },
    async beforeCreate() {
        this.$store.dispatch('app/setIsLoaded', false)

        // Executa em sequência e para no primeiro erro, se houver
        try {
            // Pega informações da company atual
            await this.$store.dispatch('company/fetchData')
            // Pega as configurações da company (regras etc.)
            await this.$store.dispatch('company/fetchSettings')
            // Pega informações de todos os níveis
            await this.$store.dispatch('levels/fetchData')
        } catch (e) {
            this.preErrorHandler(e)
        }

        // Se deu algum erro no carregamento da company, mostra mensagem de erro
        if (!this.$store.getters['company/isLoaded']) {
            this.exist       = false
            this.$store.dispatch('app/setIsLoaded', true)
            return
        }

        // Se a company está bloqueada, mostra mensagem de erro
        if (this.$store.getters['company/blocked_at']) {
            this.isAvailable = false
            this.$store.dispatch('app/setIsLoaded', true)
            return
        }

        // Ativas as ferramentas de analytics disponíveis
        this.enableAnalytics()

        try {
            // Atenção: Services deve vir antes de fetchCurrentCustomer
            await InitServices.apply(this)

            // Quando foi logado pelo firebase, ele vai disparar um evento de login
            // Quando foi logado pelo email, temos que esperar a aplicação pegar o customer
            if (this.$store.getters['auth/check'] && this.$store.getters['auth/token_type'] == 'EMAIL') {
                await this.$store.dispatch('auth/fetchCurrentCustomer')
            }
        } catch (e) {
            this.preErrorHandler(e)
        }

        this.$store.dispatch('app/setIsLoaded', true)
    },
    updated() {
        if (
            this.isAgeRestrictionEnabled                        && 
            this.isAgeGateEnabled                               &&
            this.hasAgeRestriction                              &&
            !this.isValidUserAge                                &&
            !this.$persistence.getItem('age_gate_confirmation')
        )
            this.$refs.age_gate.open()
    },
    beforeDestroy() {
        // Para de escutar todos os eventos
        this.$bus.$off()
    },
    methods: {
        toggleDrawer() {
            this.isDrawerOpen = !this.isDrawerOpen
        },
        enableAnalytics() {
            let analytics = this.$lodash.get(this, 'settings.analytics')
            if (!analytics)
                return

            // Google Tag Manager
            if (analytics.gtm_id) {
                this.$gtm.id = analytics.gtm_id
                this.$gtm.enable(true)
            }
        },

        preErrorHandler(e) {
            // [TODO] Tratar caso dê erro em alguma request fundamental de inicialização

            this.errorHandler(e)
        },
    },
    computed: {
        ...mapGetters({
            isAuthenticated: 'auth/check',
            isAppLoaded    : 'app/isLoaded',
            settings       : 'company/settings',
            user           : 'auth/user',
        }),
        shouldShowNavbar() {
            return !this.$route.meta.noNavbar
        },
        shouldDrawer() {
            return !this.$route.meta.noDrawer
        },
        isAgeRestrictionEnabled() {
            return this.$lodash.get(this, 'settings.is_age_restriction_enabled')
        },
        isAgeGateEnabled() {
            return this.$lodash.get(this, 'settings.is_age_gate_enabled')
        },
        allowFromAge() {
            return this.$lodash.get(this, 'settings.allow_from_age')
        },
        allowToAge() {
            return this.$lodash.get(this, 'settings.allow_to_age')
        },
        hasAgeRestriction() {
            return !this.$lodash.isNil(this.allowFromAge) || !this.$lodash.isNil(this.allowToAge)
        },
        isValidUserAge() {
            let userAge = this.$lodash.get(this, 'user.age')
            if (!userAge)
                return false

            return (this.$lodash.isNil(this.allowFromAge) || userAge >= this.allowFromAge) &&
                   (this.$lodash.isNil(this.allowToAge)   || userAge <= this.allowToAge  )
        },
        contentStyles() {
            return this.isAuthenticated && this.shouldDrawer ? 'ml-md-14 ml-lg-12' : ''
        },
    },
    watch: {
        // Toda vez que troca de rota fecha a drawer
        '$route.name'() {
            this.isDrawerOpen = false
        },
    },
}
</script>

<style lang="scss">
.content {
    background: $background;
}
.intention-banner{
    position: absolute;
    z-index: 8 !important;
}

.drawer {
    z-index: 9 !important;
}

// Ajuste no espaçamento esquerdo em telas médias
//  O valor de 982px é importante para o componente CustomerLevel não quebrar durante o intervalor de 960px até 988px
@media (min-width: 988px) {
    .container {
        margin-right: 1rem;
        max-width: 96%;
    }    
}
</style>
