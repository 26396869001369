var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("BaseModal", {
    ref: "modal",
    attrs: { persistent: "", fullscreen: "" },
    on: { closed: _vm.onClosed },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function() {
          return [
            _c(
              "v-card",
              {
                staticClass:
                  "d-flex justify-center align-center px-3 container-card",
                style: _vm.computedBgStyle
              },
              [
                _c("div", { style: _vm.computedLyStyle }),
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex flex-column py-8 px-7 background content"
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "d-flex mb-6" },
                      [
                        _vm.logo_url
                          ? _c("v-img", {
                              staticClass: "mx-auto mb-4 pb-4",
                              attrs: {
                                src: _vm.logo_url,
                                "max-width": "190",
                                "max-height": "190",
                                contain: ""
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "d-flex flex-column" },
                      [
                        _c(
                          "v-card-title",
                          {
                            staticClass:
                              "mb-4 justify-center font-weight-bold primary--text"
                          },
                          [_vm._v(" " + _vm._s(_vm.title) + " ")]
                        ),
                        _c(
                          "v-card-subtitle",
                          { staticClass: "mb-0 text-center" },
                          [_vm._v(" " + _vm._s(_vm.subtitle) + " ")]
                        ),
                        _c(
                          "v-card-actions",
                          { staticClass: "pt-0 justify-center" },
                          [
                            _c(
                              "v-btn",
                              {
                                staticClass: "mr-2",
                                attrs: {
                                  depressed: "",
                                  height: "45px",
                                  width: "106.5px"
                                },
                                on: { click: _vm.handleNo }
                              },
                              [_vm._v(" " + _vm._s(_vm.$t("globals.no")) + " ")]
                            ),
                            _c(
                              "v-btn",
                              {
                                staticClass: "ml-2 primary",
                                attrs: {
                                  depressed: "",
                                  height: "45px",
                                  width: "106.5px"
                                },
                                on: {
                                  click: _vm.handleYes,
                                  keyup: function($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.handleConfirm($event)
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("globals.yes")) + " "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                )
              ]
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }