import axios from './axios'

export async function generateIntegrationToken() {
    const response = await axios({
        url: '/customer/generate-integration-token',
        method: 'get',
    })

    if(!response || response.status != 200)
        return

    return response.data
}
