import $store from '@/store'

export default [
    {
        path: '/store',
        name: 'prizes',
        component: () => import(/* webpackChunkName: "prizes" */ '@/views/prizes/Prizes.vue'),
        meta: {
            mustHaveCompleteProfile: true,
            requiresAuthentication : true,
            title                  : 'prizes',
        },
    },
    {
        path: '/store/:id',
        name: 'prizes.details',
        component: () => import(/* webpackChunkName: "prizes.details" */ '@/views/prizes/PrizeDetails.vue'),
        meta: {
          mustHaveCompleteProfile: true,
          requiresAuthentication : true,
          title                  : 'prizes',
        },
    }
]