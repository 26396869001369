export default [
    {
        path: '/historico',
        name: 'history',
        props: true,
        component: () => import(/* webpackChunkName: "history" */ '@/views/history/HistoryView.vue'),
        meta: {
            mustHaveCompleteProfile : true,
            requiresAuthentication  : true,
            title                   : 'history',
        },
    }
]