<template>
    <BaseModal 
        ref="modal" 
        persistent
        fullscreen
        @closed="onClosed"
    >
        <template #content>
            <v-card class="d-flex justify-center align-center px-3 container-card" :style="computedBgStyle">
                <div :style="computedLyStyle"></div>
                <div class="d-flex flex-column py-8 px-7 background content">
                    <div class="d-flex mb-6">
                        <v-img v-if="logo_url" :src="logo_url" max-width="190" max-height="190" class="mx-auto mb-4 pb-4" contain/>
                    </div>

                    <div class="d-flex flex-column">
                        <v-card-title class="mb-4 justify-center font-weight-bold primary--text">
                            {{ title }}
                        </v-card-title>

                        <v-card-subtitle class="mb-0 text-center">
                            {{ subtitle }}
                        </v-card-subtitle>

                        <v-card-actions class="pt-0 justify-center">
                            <v-btn 
                                depressed
                                height="45px" width="106.5px"
                                class="mr-2"
                                @click="handleNo"
                            >
                                {{ $t('globals.no') }}
                            </v-btn>

                            <v-btn  
                                depressed
                                height="45px" width="106.5px"
                                class="ml-2 primary"
                                @click="handleYes"
                                @keyup.enter="handleConfirm"
                            >
                                {{ $t('globals.yes') }}
                            </v-btn>
                        </v-card-actions>
                    </div>
                </div>
            </v-card>
        </template>
    </BaseModal>
</template>

<script>
import HasBackgroundMixin from '@/mixins/HasBackgroundMixin'
import BaseModal from '@/components/modals/BaseModal'
import { mapGetters } from 'vuex'

export default {
    mixins: [ HasBackgroundMixin ],
    components: {
        BaseModal
    },
    data: vm => {
        return {
            confirm              : vm.$t('modals.AlertModal.confirm'),
            color                : '',
            isSuccess            : null,

            // Armazena funções da promessa atual, por padrão, função nula
            currentPromise       : null,
            currentPromiseResolve: () => null,
            currentPromiseReject : () => null,
        }
    },
    methods: {
        open() {
            this.$emit('opening')
            this.currentPromise = new Promise((resolve, reject) => {
                this.currentPromiseResolve = resolve
                this.currentPromiseReject  = reject
            })
            this.$refs.modal.open()
            this.$emit('opened')
        },
        close() {
            this.$emit('closing')
            this.$refs.modal.close()
            this.$emit('closed')
        },
        onClosed() {
            this.$emit('closed')
            this.resolvePromise()
        },
        resolvePromise() {
            typeof this.currentPromiseResolve == 'function' && this.currentPromiseResolve()
        },
        handleNo() {
            if (this.ageGateUrl)
                window.location.href = this.ageGateUrl
        },
        handleYes() {
            this.$persistence.setItem('age_gate_confirmation', true)
            this.close()
        },
    },
    computed: {
        ...mapGetters({
            logo_url: 'company/logo_url',
            settings: 'company/settings',
        }),

        allowFromAge() {
            let value = this.$lodash.get(this, 'settings.allow_from_age')
            return value ? Number(value) : undefined
        },
        allowToAge() {
            let value = this.$lodash.get(this, 'settings.allow_to_age')
            return value ? Number(value) : undefined
        },
        title() {
            return this.$t('modals.AgeGateModal.title').toUpperCase()
        },
        subtitle() {
            if (this.allowFromAge && this.allowToAge)
                return this.$t('modals.AgeGateModal.subtitle.from_and_to', { fromAge: this.allowFromAge, toAge: this.allowToAge })
            
            if (this.allowFromAge)
                return this.$t('modals.AgeGateModal.subtitle.from', { fromAge: this.allowFromAge })

            if (this.allowToAge)
                return this.$t('modals.AgeGateModal.subtitle.to', { toAge: this.allowToAge })

            return ''
        },
        ageGateUrl() {
            return this.$lodash.get(this, 'settings.age_gate_url')
        },
    },
}
</script>

<style scoped lang="scss">
.container-card {
    border-radius: 0 !important;
}

.content {
    z-index: 1;
    border-radius: 8px !important;
    width: 374px;
}

.v-card__title {
    font-size: 24px;
}

.v-card__subtitle {
    font-size: 16px;
}
</style>