// Initial state
const state = {
    isFirstBalanceUpdate: true,
    amountBalance       : 0,
    valueBalance        : 0,
    spendtAmount        : 0,
}

// Getters
const getters = {
    isFirstBalanceUpdate: state => state.isFirstBalanceUpdate,
    amountBalance       : state => state.amountBalance,
    valueBalance        : state => state.valueBalance,
    spendtAmount        : state => state.spendtAmount,
}

// Mutations
const mutations = {
    SET_IS_FIRST_BALANCE_UPDATE(state, value = false) {
        state.isFirstBalanceUpdate = value
    },
    SET_AMOUNT_BALANCE(state, value = 0) {
        state.amountBalance = value
    },
    SET_VALUE_BALANCE(state, value = 0) {
        state.valueBalance = value
    },
    SET_SPENDT_AMOUNT(state, value = 0) {
        state.spendtAmount = value
    }
}

// Actions
const actions = {
    setIsFirstBalanceUpdate({ commit }, value) {
        commit('SET_IS_FIRST_BALANCE_UPDATE', value)
    },
    setAmountBalance({ commit }, value) {
        commit('SET_AMOUNT_BALANCE', value)
    },
    setValueBalance({ commit }, value) {
        commit('SET_VALUE_BALANCE', value)
    },
    setSpendAmount({ commit }, value) {
        commit('SET_SPENDT_AMOUNT', value)
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
 