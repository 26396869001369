<template>
    <v-app-bar app color="navbar" dark flat height="70px">
        <!-- Drawer -->
        <v-app-bar-nav-icon v-if="$vuetify.breakpoint.smAndDown && isAuthenticated && user && user.hasCompleteBaseInformations()" @click="toggleDrawer" class="ml-md-0 primary--text"></v-app-bar-nav-icon>
        <!-- <v-app-bar-nav-icon v-if="isAuthenticated" @click="toggleDrawer"></v-app-bar-nav-icon> -->

        <v-spacer v-if="$vuetify.breakpoint.smAndDown && !isSimpleLogout"></v-spacer>

        <v-toolbar-title @click="titleClick" class="mr-0 ml-md-6 pointer clickable">
            <v-img v-if="logo_url" class="mx-0" :src="logo_url" contain max-width="160" max-height="56"/>
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <v-chip
            class="secondary_font--text font-weight-medium mr-4 body-2"
            v-if="!hideCashback && $vuetify.breakpoint.smAndUp"
            color="primary"
            outlined
        >
            <span class="primary--text">{{ $t('drawer.balance') }}</span>
            <span class="primary--text ml-1">
                <Balance :showPoints="false"/>
            </span>
        </v-chip>

        <!-- Botões de autenticação -->
        <template v-if="!isAuthenticated">
            <v-btn text @click="welcome" :href="landingPageUrl">
                <span>{{ $t('navbar.welcome') }}</span>
            </v-btn>

            <v-btn v-show="shouldShowLoginButton" :to="{ name: 'login' }" color="success" depressed class="ml-3">
                <span>{{ $t('navbar.login') }}</span>
            </v-btn>
        </template>

        <!-- Configurações de usuário -->
        <template v-else-if="isSimpleLogout">
            <v-btn text color="black" :to="{ name: 'logout' }">
                <span>{{ $t('navbar.logout') }}</span>
            </v-btn>
        </template>

        <template v-else>
            <NotificationIcon/>

            <v-menu v-if="$vuetify.breakpoint.mdAndUp" offset-y>
                <template v-slot:activator="{ on }">
                    <v-btn text v-on="on">
                        <span v-if="user" class="mr-2 secondary_font--text limit-user-name text-capitalize">{{ user.name | removeMiddle }}</span>
                        <v-icon small class="ml-2 secondary_font--text">fas fa-angle-down</v-icon>
                    </v-btn>
                </template>
                
                <v-list>
                    <v-list-item v-if="user && user.hasCompleteBaseInformations()" :to="{ name: 'settings' }">
                        <v-list-item-title>{{ $t('navbar.settings') }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item :to="{ name: 'logout' }">
                        <v-list-item-title>{{ $t('navbar.logout') }}</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </template>
    </v-app-bar>
</template>

<script>
import { mapGetters } from 'vuex'
import Balance from '@/components/structure/balance/Balance.vue'
import NotificationIcon from '@/components/structure/NotificationIcon.vue'

export default {
    name: 'Navbar',
    components: { Balance, NotificationIcon },
    methods: {
        titleClick() {
            this.$router.push({ name: 'home' })
        },
        toggleDrawer() {
            this.$emit('toggleDrawer')
        },
        welcome() {
            if (!this.landingPageUrl) {
                this.configError = true
                return
            }

            window.location = this.landingPageUrl
        },
    },
    computed: {
        ...mapGetters({
            isAuthenticated: 'auth/check',
            logo_url       : 'company/logo_url',
            user           : 'auth/user',
            landingPageUrl : 'company/landing_page_domain',
            hideCashback   : 'company/hide_cashback',
        }),
        shouldShowLoginButton() {
            const routesToHide = ['login', 'auth.email_login']
            return !routesToHide.includes(this.$route.name)
        },
        isSimpleLogout() {
            return this.$route.meta.isSimpleLogout
        },
    },
}
</script>

<style lang="scss" scoped>
.limit-user-name {
    max-width: 200px;
}

.v-chip.v-size--default {
    border-radius: 8px !important;
}
</style>