var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _vm.isAppLoaded || _vm.inMaintenance
        ? [
            _vm.isAppLoaded &&
            !_vm.inMaintenance &&
            _vm.exist &&
            _vm.isAvailable
              ? [
                  _vm.isAuthenticated && _vm.shouldDrawer
                    ? _c("Drawer", {
                        staticClass: "drawer",
                        model: {
                          value: _vm.isDrawerOpen,
                          callback: function($$v) {
                            _vm.isDrawerOpen = $$v
                          },
                          expression: "isDrawerOpen"
                        }
                      })
                    : _vm._e(),
                  _vm.shouldShowNavbar
                    ? _c("Navbar", { on: { toggleDrawer: _vm.toggleDrawer } })
                    : _vm._e(),
                  _c(
                    "v-content",
                    { staticClass: "content", class: _vm.contentStyles },
                    [
                      _c(
                        "div",
                        { staticClass: "d-flex flex-column fill-height" },
                        [
                          _vm.isAuthenticated
                            ? _c("GeneralAlert", { ref: "generalAlert" })
                            : _vm._e(),
                          _c("AuthorizationBanner", {
                            staticClass: "intention-banner mt-2"
                          }),
                          _c("router-view"),
                          _c("Footer", { staticClass: "mt-auto" })
                        ],
                        1
                      )
                    ]
                  )
                ]
              : [
                  !_vm.inMaintenance && !_vm.exist
                    ? _c(
                        "v-row",
                        { staticStyle: { "background-color": "#FFFFFF" } },
                        [
                          _c("img", {
                            attrs: {
                              height: "100%",
                              width: "100%",
                              src: require("@/assets/errors/404.png")
                            }
                          })
                        ]
                      )
                    : _vm._e(),
                  _vm.inMaintenance || !_vm.isAvailable
                    ? _c(
                        "v-row",
                        { staticStyle: { "background-color": "#FFFFFF" } },
                        [
                          _c("img", {
                            attrs: {
                              height: "100%",
                              width: "100%",
                              src: require("@/assets/errors/maintenance.png")
                            }
                          })
                        ]
                      )
                    : _vm._e()
                ]
          ]
        : [
            _c("div", { staticClass: "text-center mt-5" }, [
              _vm._v(" " + _vm._s(_vm.$t("globals.loading")) + " ")
            ])
          ],
      _c("AgeGateModal", { ref: "age_gate" }),
      _c("NotificationFooter"),
      _c("AppSnackbar", { ref: "snackbar" }),
      _c("AlertModal", { ref: "alert" }),
      _c("ConfirmationModal", { ref: "confirmation" })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }