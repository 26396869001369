export default [
    {
        path: '/promo',
        name: 'promotion',
        props: true,
        component: () => import(/* webpackChunkName: "promotion" */ '@/views/promotion/trigger/TriggerPromotionView.vue'),
        meta: {
            mustHaveCompleteProfile : true,
            requiresAuthentication  : true,
            title                   : 'promotion',
        },
    },
    {
        path: '/promo/t/:promotion_code',
        name: 'promotion.trigger',
        props: true,
        component: () => import(/* webpackChunkName: "promotion" */ '@/views/promotion/trigger/TriggerPromotionView.vue'),
        meta: {
            mustHaveCompleteProfile : true,
            requiresAuthentication  : true,
            title                   : 'promotion',
        },
    }
]