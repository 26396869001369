import axiosInstance  from '@/services/axios/index'
import Intention      from '@/models/Intention'

const state = {
    authorization: null,
}

const getters = {
    authorization:    state => state.authorization,
    hasAuthorization: state => !!state.authorization,
}

const mutations = {
    SET_AUTHORIZATION: (state, authorization) => {
        state.authorization = authorization
    },
}

const actions = {
    async getAuthorization({ commit }) {
        let res = await axiosInstance.get('points/intention')

        let data = res && res.data || null

        commit('SET_AUTHORIZATION', data ? new Intention(data) : null)
    },
    async deleteAuthorization({ commit }) {
        commit('SET_AUTHORIZATION', null)
    },
}

export default {
    namespaced: true,
    state, getters, mutations, actions
}