<template>
    <v-dialog 
        v-if="isVisible" 
        v-model="isVisible" 
        :width="width" 
        :max-width="maxWidth" 
        @keydown.enter="handleConfirm" 
        :persistent="persistent"
        v-bind="attrs"
        v-on="listeners"
    >
        <slot name="content">
            <v-card class="pa-2">
                <div v-if="customConfirm" :class="customConfirmClass" class="mt-9">
                    <template>
                        <slot name="icon" />
                    </template>
                </div>

                <v-card-title v-if="hasTitle" color="success" :class="customConfirmClass">
                <span class="primary--text font-weight-bold body-1 card-text">{{ title }}</span>
                </v-card-title>

                <v-card-subtitle v-if="hasSubtitle" class="pt-2 card-text" :class="customConfirmClass">
                    {{ subtitle }}
                </v-card-subtitle>

                <v-card-text v-if="hasText" class="py-3 mb-4 text-center card-text">
                    <span class="primary_font--text">{{ text }}</span>

                    <slot name="text" />
                </v-card-text>

                <v-card-actions class="mb-3 mx-6 pa-0" v-if="hasActions" 
                    :class="customConfirmClass" 
                >
                    <v-spacer v-if="!customConfirm" />

                    <v-btn elevation="0" v-if="hasCancel" :color="cancelColor" 
                        @click="handleCancel" :disabled="cancelDisabled"
                        height="36px" width="86px"
                    >
                        {{ cancel }}
                    </v-btn>

                    <v-btn v-if="hasConfirm" :color="confirmColor" 
                        @click="handleConfirm" @keyup.enter="handleConfirm"
                        :disabled="confirmDisabled" depressed elevation="0"
                        height="36px" width="86px"
                    >
                        {{ confirm }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </slot>
    </v-dialog>
</template>

<script>
export default {
    name: 'BaseModal',
    props: {
        persistent: {
            type: Boolean,
            default: false
        },
        width: {
            type: String,
            default: '80%'
        },
        maxWidth: {
            type: String,
            default: '500px'
        },
        hasTitle: {
            type: Boolean,
            default: true
        },
        hasSubtitle: {
            type: Boolean,
            default: true
        },
        hasText: {
            type: Boolean,
            default: true
        },
        hasActions: {
            type: Boolean,
            default: true
        },
        hasCancel: {
            type: Boolean,
            default: true
        },
        hasConfirm: {
            type: Boolean,
            default: true
        },
        title: {
            type: String,
            default: ''
        },
        subtitle: {
            type: String,
            default: ''
        },
        text: {
            type: String,
            default: ''
        },
        cancel: {
            type: String,
            default: ''
        },
        confirm: {
            type: String,
            default: ''
        },
        cancelColor: {
            type: String,
            default: 'error'
        },
        confirmColor: {
            type: String,
            default: 'success'
        },
        customCancel: {
            type: Boolean,
            default: false
        },
        customConfirm: {
            type: Boolean,
            default: false
        },
        cancelDisabled: {
            type: Boolean,
            default: false
        },
        confirmDisabled: {
            type: Boolean,
            default: false
        },
        isForm: {
            type: Boolean,
            default: false,
        }
    },
    data: vm => {
        return {
            isVisible: false
        }
    },
    methods: {
        open() {
            this.$emit('opening')
            this.isVisible = true
            this.$emit('opened')
        },
        close() {
            this.$emit('closing')
            this.isVisible = false
            this.$emit('closed')
        },
        handleCancel() {
            if (this.customCancel) {
                this.$emit('close')
            }

            this.close()
        },
        handleConfirm() {
            if (this.customConfirm || this.isForm) {
                this.$emit('confirm')
            }

            this.close()
        }
    },
    computed: {
        /**
         * Extrai todos os listeners deste componente, exceto os que estamos sobrescrevendo
         */
        listeners() {
            const { ...listeners } = this.$listeners
            return listeners
        },

        /**
         * Extrai todas as props deste componente, exceto as que estamos sobrescrevendo
         */
        attrs() {
            const { ...attrs } = this.$attrs
            return { ...attrs }
        },

        customConfirmClass() {
            if (!this.customConfirm) return ''

            return 'd-flex justify-center text-center'
        }
    }
}
</script>

<style scoped>
.card-text {
    letter-spacing: 0.0125em !important;
    line-height: 1.75rem;
}
</style>