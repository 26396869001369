export default [
    {
        path: '/niveis',
        name: 'levels',
        props: true,
        component: () => import(/* webpackChunkName: "levels" */ '@/views/levels/LevelsView.vue'),
        meta: {
            mustHaveCompleteProfile : true,
            requiresAuthentication  : true,
            title                   : 'levels',
        },
    }
]