import BaseModel from '@/models/BaseModel'

export default class Company extends BaseModel {

    defaults() {
        return {
            name               : null,
            program_name       : null,
            logo_url           : null,
            favicon_url        : null,
            font_color         : null,
            primary_color      : null,
            background_color   : null,
            background_url     : null,
            landing_page_domain: null,
            frontend_domain    : null,
            admin_domain       : null,
            display_mode       : null,
            hide_cashback      : null,
            hide_points        : null,
            
            // google_firebase_app_key: {},
            home               : {
                hero    : {},
                features: {},
            },
            banner      : {},
            how_it_works: {
                hero: {}
            },
            faq: {},

            legal: {
                cookie_policy_url : '',
                privacy_policy_url: '',
                terms_url         : '',
                custom_terms_html : '',
            },

            footer: {
                mark: {
                    text: '',
                    url : ''
                },

                text : '',
                links: [],
            },
        }
    }

    // Não precisa de validação, pois não havera o cadastro nesta app
}