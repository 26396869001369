export default {
    generic: {
        title: '{program_name}',
        meta: 'Com o {program_name} você acumula pontos e troca por produtos! Comece já a aproveitar os benefícios de ser {program_name}.',
        default_program_name: 'CPlug Fidelidade',
    },
    authorization: {
        title: '{program_name} — Autorização de consumo',
        meta: '',
    },
    home: {
        title: '{program_name} — Resumo',
        meta: '',
    },
    register: {
        title: '{program_name} — Cadastre-se agora',
        meta: '',
    },
    login: {
        title: '{program_name} — Entrar na sua conta',
        meta: '',
    },
    'complete-profile': {
        title: '{program_name} — Complete seu perfil',
        meta: '',
    },
    'confirm-email': {
        title: '{program_name} — Confirme seu email',
        meta: '',
    },
    password_reset: {
        title: '{program_name} — Redefinição de Senha',
        meta: '',
    },
    settings: {
        title: '{program_name} — Meu cadastro',
        meta: '',
    },
    prizes: {
        title: '{program_name} — Produtos',
        meta: '',
    },
    levels: {
        title: '{program_name} — Níveis',
        meta: '',
    },
    history: {
        title: '{program_name} — Histórico',
        meta: '',
    },
    promotion: {
        title: '{program_name} — Ativar promoção',
        meta: '',
    },
    faq: {
        title: '{program_name} — {faq_title}',
        meta: '',
    }
}