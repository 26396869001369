var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("BaseModal", {
    ref: "modal",
    attrs: {
      title: _vm.title,
      subtitle: _vm.subtitle,
      text: _vm.text,
      hasText: _vm.hasText,
      hasSubtitle: _vm.hasSubtitle,
      confirm: _vm.confirm,
      cancel: _vm.cancel,
      customConfirm: true,
      "max-width": "400px",
      confirmColor: "primary",
      cancelColor: "background"
    },
    on: {
      confirm: _vm.handleConfirm,
      closed: function($event) {
        return _vm.$emit("closed")
      }
    },
    scopedSlots: _vm._u([
      {
        key: "icon",
        fn: function() {
          return [
            _c("v-icon", { attrs: { color: "#9A9CB8", size: 45 } }, [
              _vm._v("$cp_alert_octagon")
            ])
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }