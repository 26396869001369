<template>
    <BaseModal 
        ref="modal" 
        :title="title" :subtitle="subtitle"
        :hasCancel="false" :confirm="confirm" :confirmColor="color"
        max-width="400px"
        @closed="onClosed"
    >
        <template #text>
            <template v-if="!useHtml">
                {{ text }}
            </template>
            <div v-else v-html="text">
            </div>
        </template>
    </BaseModal>
</template>

<script>
import BaseModal from '@/components/modals/BaseModal'

export default {
    components: {
        BaseModal
    },
    data: vm => {
        return {
            title    : '',
            subtitle : '',
            text     : '',
            confirm  : vm.$t('modals.AlertModal.confirm'),
            color    : '',
            isSuccess: null,
            useHtml  : false,

            // Armazena funções da promessa atual, por padrão, função nula
            currentPromise       : null,
            currentPromiseResolve: () => null,
            currentPromiseReject : () => null,
        }
    },
    methods: {
        open() {
            this.$emit('opening')
            this.currentPromise = new Promise((resolve, reject) => {
                this.currentPromiseResolve = resolve
                this.currentPromiseReject  = reject
            })
            this.$refs.modal.open()
            this.$emit('opened')
        },
        close() {
            this.$emit('closing')
            this.$refs.modal.close()
            this.$emit('closed')
        },
        async showSuccess(text, useHtml = false) {
            this.title     = this.$t('modals.AlertModal.success.title')
            this.subtitle  = this.$t('modals.AlertModal.success.subtitle')
            this.text      = text
            this.color     = 'success'
            this.isSuccess = true
            this.useHtml   = useHtml
            this.open()

            // Espera a interação do usuário
            await this.currentPromise
        },
        async showError(text, useHtml = false) {
            this.title     = this.$t('modals.AlertModal.error.title')
            this.subtitle  = this.$t('modals.AlertModal.error.subtitle')
            this.text      = text
            this.color     = 'error'
            this.isSuccess = false
            this.useHtml   = useHtml
            this.open()

            // Espera a interação do usuário
            await this.currentPromise
        },
        onClosed() {
            this.$emit('closed')
            this.resolvePromise()
        },
        resolvePromise() {
            typeof this.currentPromiseResolve == 'function' && this.currentPromiseResolve()
        },
    }
}
</script>