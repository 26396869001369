import SharedCompanySettings from '@/shared/models/CompanySettings'
import _ from 'lodash'
// import $store from '@/store'

export default class CompanySettings extends SharedCompanySettings {
    // Métodos para validação dos campos customizados

    /**
     * Verifica se o numero total de campos pertencente ao modelo é par.
     *
     * @param {string} model nome da Model
     * @param {object} customFields Objeto com os campos que são opcionais para o modelo
     * @param {array}  requestedFields Array com os campos que são obrigatórios para o formulário
     * @returns {boolean}
      */
    fieldsNumberInUseIsPair(model, customFields, requestedFields = []) {
        let countFields = this.countTotalFieldsInUse(model, customFields, requestedFields)
        return countFields.totalFields % 2 === 0  ? true : false  
    }

    /**
     * Determina o numero total de campos utilizados de campos
     *
     * @param {string} model Nome do Model
     * @param {object} customFields Objeto com os campos que são opcionais para o modelo
     * @param {array} requestedFields Array com os campos que são obrigatórios para o formulário
     * 
     * @returns {object}  r                    objeto de retorno
     * @returns {number} r.totalFields:       quantidade total de campos, 
     * @returns {number} r.requestedFields:   quantidade total de campos obrigatórios, 
     * @returns {boolean} r.hasAdditionFields: verificar se totalFields é igual a requestedFields
      */
    countTotalFieldsInUse(model, customFields, requestedFields = []) {
        if (_.isEmpty(customFields) || !model) 
            return { totalFields: 0, requestedFieldsLength: 0, hasAdditionFields: true }

        let requestedFieldsLength = requestedFields.length
        let totalFields           = requestedFieldsLength + _.filter(customFields[model], el => { return el.active}).length

        return { 
            totalFields, 
            requestedFieldsLength,
            hasAdditionFields: totalFields === requestedFieldsLength,
        }
    }
}