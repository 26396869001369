import axiosInstance  from '@/services/axios/index'
import SimpleCustomer from '@/models/SimpleCustomer'
import Cookie         from 'js-cookie'
import $router        from '@/router'
import $firebase      from '@/services/firebase'
import $persistence   from '@/services/persistence'
import $constants     from '@/services/constants'
import _              from 'lodash'

const DAYS_TO_EXPIRE      = _.toNumber(process.env.VUE_APP_TOKEN_DAYS_TO_EXPIRE || 60)
const SECURE_COOKIES      = ['production', 'staging'].includes(process.env.NODE_ENV)
const TOKEN_TYPE_EMAIL    = 'EMAIL'
const TOKEN_TYPE_FIREBASE = 'FIREBASE'

const state = {
    token     : Cookie.get('frontend_token'),
    token_type: $persistence.getItem('frontend_token_type'),
    user      : null
}

const getters = {
    token     : state => state.token,
    token_type: state => state.token_type,
    check     : state => !!state.token,
    user      : state => state.user,
}

const mutations = {
    SET_TOKEN_TYPE: (state, token_type) => {
        state.token_type = token_type
        $persistence.setItem('frontend_token_type', token_type, DAYS_TO_EXPIRE)
    },
    SET_TOKEN: (state, token) => {
        state.token = token
        Cookie.set('frontend_token', token, { expires: DAYS_TO_EXPIRE, secure: SECURE_COOKIES })
    },
    
    UNSET_TOKEN: (state) => {
        state.token = null
        Cookie.remove('frontend_token')
        $persistence.removeItem('frontend_token_type')
    },

    SET_USER: (state, value) => state.user = value,
}

const actions = {
    async linkLogin({ commit, dispatch }, data) {
        if (!data || !data.token || !data.email)
            throw new Error('Token ou email ausentes no link')
        
        let res = await axiosInstance.post('/customer/login', data)
        // Catch vai cair pra cima

        let customer = new SimpleCustomer(res.data.data)

        commit('SET_USER', customer)
        commit('SET_TOKEN', res.data.token)
        commit('SET_TOKEN_TYPE', TOKEN_TYPE_EMAIL)

        if (res.data.token)
            await dispatch('fetchCurrentCustomer')

        // Verifica se é o primeiro login desse admin
        $router.push({ name: 'complete-profile' })

        return true
    },

    async identifierLogin({ commit, dispatch }, data) {
        let res = await axiosInstance.post('/customer/login', data)
        // Catch vai cair pra cima

        if (!res || !res.data || _.isEmpty(res.data.data) || _.isEmpty(res.data.token))
            throw new Error('Resposta incorreta no login. Falta data & token.')

        let customer = new SimpleCustomer(res.data.data)

        commit('SET_USER', customer)
        commit('SET_TOKEN', res.data.token)
        commit('SET_TOKEN_TYPE', TOKEN_TYPE_EMAIL)

        if (res.data.token)
            await dispatch('fetchCurrentCustomer')

        return true
    },

    async emailRegister({ commit, dispatch }, data) {
        let res = await axiosInstance.post('/customer/create-via-email', data)
        // Catch vai cair pra cima

        if (!res || !res.data || _.isEmpty(res.data.data) || _.isEmpty(res.data.token))
            throw new Error('Resposta incorreta no login. Falta data & token.')

        let customer = new SimpleCustomer(res.data.data)

        commit('SET_USER', customer)
        commit('SET_TOKEN', res.data.token)
        commit('SET_TOKEN_TYPE', TOKEN_TYPE_EMAIL)

        if (res.data.token)
            await dispatch('fetchCurrentCustomer')

        // Verifica se é o primeiro login desse admin
        let nextRoute = _.get($router, 'currentRoute.query.redirect', { name: 'home' })
        $router.push(nextRoute)

        return true
    },

    async login({ commit, dispatch }, data) {
        // Valida o modelo do login
        if (!(data instanceof SimpleCustomer))
            throw new TypeError('Esperava o modelo SimpleCustomer no login.')

        let token = await data.firebaseResult.getIdToken()

        commit('SET_USER',  data)
        commit('SET_TOKEN', token)
        commit('SET_TOKEN_TYPE', TOKEN_TYPE_FIREBASE)

        if (token) {
            let response = await axiosInstance.get('/customer/generate-token')
                .catch(err => {
                    if (err.response.status != '404')
                        throw err
                })

            if (response && response.data && response.data.token) {
                token = response.data.token
                commit('SET_TOKEN', token)
            }
        }

        if (token)
            await dispatch('fetchCurrentCustomer')

        
        let nextRoute = _.get($router, 'currentRoute.query.redirect', { name: 'home' })
        $router.push(nextRoute)

        return true
    },
    
    async logout({ commit }, params) {
        params = params || {}

        // Remove o token que estava guardado no navegador
        commit('UNSET_TOKEN')
        commit('SET_USER', null)

        // Faz logout na api / invalida o token
        // await MockService.logout()

        // Redireciona para o login, informando que realizou o logout
        $router.push({ name: 'login', params: { ...{ logout: true }, ...params } })

        // Redireciona para a landing page ?
        // window.location = process.env.VUE_APP_LANDING_URL
        // window.location = process.env.VUE_APP_LANDING_URL + '/login'

        await $firebase.logout()

        return true
    },

    setToken({ commit }, value) {
        commit('SET_TOKEN', value)
    },

    setUser({ commit }, value) {
        // $console.log('setUser', value)
        commit('SET_USER', value)
    },

    /**
     * Grava o usuário no DynamoDB
     */
    async createUser({ dispatch, commit }, data) {
        if (data.provider && data.provider.includes('google'))
            data.login_type = $constants.getConstant('CUSTOMER_LOGIN_TYPE_GOOGLE')
        else if (data.provider && data.provider.includes('facebook'))
            data.login_type = $constants.getConstant('CUSTOMER_LOGIN_TYPE_FACEBOOK')
        else if (data.provider && data.provider.includes('password'))
            data.login_type = $constants.getConstant('CUSTOMER_LOGIN_TYPE_EMAIL')
        else if (data.provider && data.provider.includes('phone'))
            data.login_type = $constants.getConstant('CUSTOMER_LOGIN_TYPE_PHONE')
        else 
            data.login_type = 'UNKNOWED'

        let customer = new SimpleCustomer(data)
        let response = await customer.createViaFirebase()
            .catch(async error => {
                const status = _.get(error, 'response.status')

                if (status == 422 && error.response.data.type == 'used_email') {
                    commit('UNSET_TOKEN')
                    commit('SET_USER', null)
                    await $firebase.logout()
                    setTimeout(() => {
                        $router.push({ name: 'login', params: { firebaseEmailExistent: true, firebaseEmail: data.email }, query: { firebaseEmailExistent: true } })
                    }, 1000)
                    return false
                }

                throw error
            })

        if (!response || !response.data || response.status != 200)
            return false

        response = await axiosInstance.get('/customer/generate-token')

        if (!response.data || !response.data.token)
            return false            

        commit('SET_TOKEN', response.data.token)

        await dispatch('fetchCurrentCustomer')
    },

    /**
     * Pega o usuário que foi autenticado utilizando o token ativo
     * e atualiza no store do vuex
     */
    async fetchCurrentCustomer({ dispatch, commit }, forceUpdateLevel = true) {
        // $console.log('fetchCurrentCustomer() !!!!!!!!!!!!!!!!!!!!!!!')

        // Pega o usuário do firebase
        let firebaseResult = window.firebase.auth().currentUser || {}
        let firebaseUser = {}

        if (!_.isEmpty(firebaseResult)) {
            firebaseUser = {
                id           : firebaseResult.uid,
                name         : firebaseResult.displayName,
                email        : firebaseResult.email,
                phone        : firebaseResult.phoneNumber,
                provider     : _.get(firebaseResult, 'providerData[0].providerId'),
                avatar_url   : firebaseResult.photoURL,
                firebase_uid : firebaseResult.uid,
                emailVerified: firebaseResult.emailVerified,
            }
        }

        // Pega o usuário da API
        let response = await axiosInstance.get('/customer', { params: { update_level: forceUpdateLevel ? 'TRUE' : 'FALSE' }})
            .catch(async error => {
                const status = _.get(error, 'response.status')

                // Se for qualquer outro erro, deve ser tratado no método que a chamou
                if (status != 404)
                    throw error

                // Se não for encontrado, tenta criar o usuário
                if (!_.isEmpty(firebaseUser) && firebaseUser.id) {
                    let r = await dispatch('createUser', firebaseUser)
                    if (r === false)
                        return
                } else
                    commit('UNSET_TOKEN')
            })

        if (!response)
            return

        let userData = response.data || {}

        // Merge do usuário atual do Firebase com o usuário da API

        // userData = { ...userData, ...firebaseUser }
        userData = firebaseUser.name ? { ...userData, ...firebaseUser } : { ...firebaseUser , ...userData }

        // Atualiza o usuário atual
        let user = new SimpleCustomer({ ...userData, firebaseResult })

        await dispatch('setUser', user)
    },
}

export default {
    namespaced: true,
    state, getters, mutations, actions
}