import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

function loadLocaleMessages() {
    const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.js$/i)
    const messages = {}

    locales.keys().forEach(key => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i)
        if (matched && matched.length > 1) {
            const locale = matched[1]
            messages[locale] = locales(key).default
        }
    })

    return messages
}

// https://kazupon.github.io/vue-i18n/guide/hot-reload.html#advanced-example

// // Load all locales and remember context
// function loadMessages() {
//     const context = require.context('./locales', false, /[a-z0-9-_]+\.js$/i)

//     console.log('context', context)

//     const messages = context
//         .keys()
//         .map((key) => {
//             console.log('map', ({ key, locale: key.match(/[a-z0-9-_]+/i)[0] }))
//             return ({ key, locale: key.match(/[a-z0-9-_]+/i)[0] })
//         })
//         .reduce(
//             (messages, { key, locale }) => { 
//                 console.log('messages', messages)
//                 console.log('key, locale', key, locale)
//                 console.log('reduce', ({
//                     ...messages,
//                     [locale]: context(key),
//                     }))
//                 return ({
//             ...messages,
//             [locale]: context(key),
//             }) },
//             {}
//         )

//     return { context, messages }
// }

// const { context, messages } = loadMessages()
const messages = loadLocaleMessages()

// console.log('context, messages', context, messages)

const i18n = new VueI18n({
    locale: process.env.VUE_APP_I18N_LOCALE || 'pt-br',
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'pt-br',
    messages,

    pluralizationRules: {
        'pt-br': (choice) => {
            if (choice >= 2)
                return 2
            else if (choice > 0)
                return 1

            return 0
        },
    },
})

// Hot updates
// if (module.hot) {
//     console.log('hot reload!!', context.id)
//     module.hot.accept(context.id, () => {
//         const { messages: newMessages } = loadMessages()
    
//         Object.keys(newMessages)
//             .filter((locale) => messages[locale] !== newMessages[locale])
//             .forEach((locale) => {
//                 messages[locale] = newMessages[locale]
//                 i18n.setLocaleMessage(locale, messages[locale])
//             })
//     })
// }

// .setLocaleMessage(lang, messages)

export default i18n