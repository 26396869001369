<template>
    <v-footer class="d-block py-0 navbar secondary_font--text small-font">
        <!-- Company footer -->
        <v-row v-if="hasMark || hasText || hasLinks" class="pt-2 px-0 px-md-4">
            <v-col cols="12" xs="12" sm="12" md="6" class="py-1">
                <v-row no-gutters>
                    <v-col cols="12" class="d-flex justify-center justify-md-start">
                        <a v-if="hasMark" :href="mark.url" target="_blank" class="text-center secondary_font--text no-decoration-link">{{ mark.text }}</a>
                    </v-col>

                    <v-col v-if="hasText" cols="12" class="d-flex justify-center justify-md-start">
                        <span class="text-center">{{ text }}</span>
                    </v-col>
                </v-row>
            </v-col>

            <v-col v-if="$vuetify.breakpoint.smAndDown && (hasMark || hasText) && hasLinks" cols="12" class="py-2">
                <v-divider class="secondary_font divider"></v-divider>
            </v-col>

            <v-col v-if="hasLinks" cols="12" xs="12" sm="12" md="6" class="d-flex py-1 align-self-center align-center justify-center justify-md-end decoreted-link">
                <a v-for="(link, i) in links" :key="i" :href="link.url" target="_blank" class="mx-2 mx-md-4 text-center secondary_font--text">
                    {{ link.text }}
                </a>
            </v-col>
        </v-row>

        <!-- ConnectPlug footer -->
        <v-row>
            <v-col cols="12" class="d-flex align-center justify-center pt-2 pt-md-1 pb-1">
                <a href="https://connectplug.com.br" target="_blank" class="d-flex align-center justify-center no-decoration-link">
                    <span class="mr-1 secondary_font--text">Desenvolvido por</span>
                    <img class="powered-by-cplug" :src="require('@/assets/logos/logo-extensive.svg')">
                </a>
            </v-col>
        </v-row>
    </v-footer>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    name: 'Footer',
    computed: {
        ...mapGetters({
            json: 'company/json',
        }),

        mark() {
            return this.$lodash.get(this, 'json.footer.mark', {})
        },
        text() {
            return this.$lodash.get(this, 'json.footer.text', '')
        },
        links() {
            return this.$lodash.get(this, 'json.footer.links', [])
        },
        hasMark() {
            return !!this.mark.text
        },
        hasText() {
            return !!this.text
        },
        hasLinks() {
            return !this.$lodash.isEmpty(this.links)
        },
    },
}
</script>

<style lang="scss" scoped>
.small-font {
    font-size: 11px;
}

.decoreted-link {
    text-decoration: underline;
}

.no-decoration-link {
    text-decoration: none;
}

.divider {
    opacity: 0.5;
}

.powered-by-cplug {
    height: 25px;
    width: auto;
}
</style>