/**
 * Este arquivo importa todas as rotas no diretorio
 */

const files = require.context('.', false, /\.js$/)
var routes = []

files.keys().forEach(key => {
    // Ignora este proprio arquivo
    if (key === './index.js') return

    let content = files(key).default
    routes = routes.concat(Array.isArray(content) ? content : [content])
})

export default routes