import BaseModel from '@/models/BaseModel'
import Prize from '@/models/Prize'

export default class Level extends BaseModel {

    defaults() {
        return {
            id              : null,
            name            : null,
            description     : null,
            description_url : null,
            color           : null,
            trigger_amount  : null,
            prev            : null,
            next            : null,
            integrator_id   : null,
            created_at      : null,
        }
    }

    relationships() {
        return {
            prize: { class: Prize, defaultValue: undefined },
        }
    }

    isFirs() {
        return !this.prev
    }

    isLast() {
        return !this.next
    }
}