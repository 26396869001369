export default {
    cards : {
        CustomerCard: {
            see_history: 'Ver histórico do cliente',
            balance : {
                amount_balance: {
                    label: 'Saldo em pontos',
                },
                value_balance: {
                    label: 'Saldo em dinheiro',
                },
                spent_value: {
                    label: 'Total gasto em compras',
                },
                received_amount: {
                    label: 'Total de pontos recebidos',
                },
            },
        },
        ConsolidatedLevelCard: {
            error: 'Ocorreu um erro ao buscar os dados do seu nível',
        },
    },
    inputs: {
        DateInput: {
            min_date: 'A data deve ser maior que {min}',
            max_date: 'A data deve ser menor que {max}',
            invalid_date: 'Data inválida',
            required: 'Este campo é obrigatório',
            invalid_format: 'Formato inválido',
        },
    },
    iterators: {
        BaseIterator: {
            no_data: 'Sem produtos para mostrar',
            search_label: 'Buscar',
        },
    },
}