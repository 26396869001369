var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-alert",
    {
      staticClass: "my-0",
      attrs: {
        dismissible: "",
        text: "",
        color: "primary",
        icon: _vm.showIcon
      },
      on: {
        input: function($event) {
          return _vm.closeAlert()
        }
      },
      model: {
        value: _vm.isOpen,
        callback: function($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen"
      }
    },
    [
      _c(
        "v-row",
        { attrs: { align: "center" } },
        [
          _c("v-col", { staticClass: "py-0", attrs: { cols: "12", md: "6" } }, [
            _vm._v(_vm._s(_vm.text))
          ]),
          _c("v-col", { staticClass: "mr-5 py-0 d-flex justify-md-end " }, [
            _vm.redirect
              ? _c("span", [
                  _c(
                    "a",
                    {
                      staticClass: "primary--text pointer link",
                      attrs: { href: _vm.action_link, target: "_blank" }
                    },
                    [_vm._v(_vm._s(_vm.action_text))]
                  )
                ])
              : _vm._e(),
            !_vm.redirect && !_vm.$lodash.isEmpty(_vm.action_router)
              ? _c(
                  "span",
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "primary--text pointer link mr-auto",
                        attrs: { to: _vm.action_router }
                      },
                      [_vm._v(_vm._s(_vm.action_text))]
                    )
                  ],
                  1
                )
              : _vm._e()
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }