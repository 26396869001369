import axiosLib from 'axios'
import axiosConfigs from './config'
import _ from 'lodash'
import $store from '@/store'
import $bugsnag from '@/services/bugsnag'
import $bus from '@/services/bus'
import $i18n from '@/i18n'
import axiosRetry from 'axios-retry'

// Pega o axios do window, se houver
const axios = window.axios || axiosLib

let instance = axios.create(axiosConfigs)

/**
 * Interceptador de requisições que garante que os padrões do endpoint fidelidade
 * sejam atendidos.
 */
instance.interceptors.request.use(
    config => {
        const token      = $store.getters['auth/token']
        const state      = $store.getters['company/state']
        const company_id = state.id

        if (token)
            config.headers['Authorization'] = `Bearer ${token}`

        if (company_id)
            config.headers['Company-Id'] = company_id

        // Precisa que data seja um objeto para enviar o Content-Type
        if (!config.data) {
            config.data = {}
        }

        return config
    },
    err => {
        // Quando ocorre um erro no axios, verifica se precisa enviar ao bugsnag

        // Verifica network error
        if (err.message == 'Network Error') {
            $bus.$emit('alert', $i18n.t('errors.network_error'), 'error')
            return Promise.reject(err)
        }

        // Só envia erros que não tem resposta, pois se tem resposta o erro vai
        // ser pego no backend
        if (!err.response) {
            // The request was made but no response was received
            if ($bugsnag.isEnabled()) {
                $bugsnag.getInstance().notify(err)
            }
        }

        return Promise.reject(err)
    }
)

/**
 * Interceptador de respostas que lida de maneira genérica com erros que
 * não foram tratados diretamente nos componentes.
 *
 * Obs. Executa sobre qualquer endpoint do fidelidade apenas.
 * Este comportamento é diferente do errorHandler que executa sobre qualquer endpoint.
 */
instance.interceptors.response.use(_.identity, async error => {
    // Trata caso seja timeout para informar parâmetros da request
    if (_.get(error, 'message', '').startsWith('timeout of')) {
        $bus.$emit('alert', $i18n.t('errors.timeout'), 'error')

        await $bugsnag.getInstance().notify('timeout', (event) => {
            event.addMetadata('request', error.request)
        })
        return
    }

    // Definimos aqui comportamentos adicionais para cada http status code.
    // Retornar true para cancelar outros handlers
    const actionMapping = {
        401: () => {
            $store.dispatch('auth/logout', { logout: false, tokenExpired: true })
            $bus.$emit('message', $i18n.t('errors.http.401'), 'error')
            return true
        },
    }

    // Por padrão, emite um snackbar com a mensagem de erro padão para determinado http status code.
    let status           = _.get(error, 'response.status', null)
    let messageIsDefined = !!$i18n.t('errors.http')[status]
    let message          = messageIsDefined ? $i18n.t(`errors.http.${status}`) : ''

    if (message) {
        $bus.$emit('message', message, 'error')
    }

    let action = actionMapping[status]
    if (typeof action === 'function') {
        if (action(error))
            return
    }

    return Promise.reject(error)
})

// Tenta requests 3 vezes antes de cair no catch
// apenas quando ocorre NetworkError ou algum outro
// tipo de requisição que possa ser tentada novamente
axiosRetry(instance, {
    retries: 3,
})

window.axiosInstance = instance

export default instance