<template>
    <v-footer app class="notification-footer pa-0" v-if="isAppLoaded && shouldShowFooter">
        <CookiesNotification
            v-if="showCookiesNotification"
            @cookies-agreement="checkForCookies"
        />
    </v-footer>
</template>
<script>
import CookiesNotification from '@/components/banner/CookiesNotificationBanner'
import CookiesAgreement    from '@/models/CookiesAgreement'
import { mapGetters }      from 'vuex'

export default {
    name: 'NotificationFooter',
    components: { CookiesNotification },
    data: vm => ({
        hasCookies: false,
    }),
    methods: {
        checkForCookies() {
            // Verifica se o usuario tem registro de cookies aceitos na camada de persistência
            let cookiesAgreement = new CookiesAgreement()
            let cookies          = cookiesAgreement.getFromPersistence()
            let hasCookiesErrors = cookiesAgreement.getFromErrorPersistence()

            if (hasCookiesErrors) {
                cookiesAgreement.deleteFromErrorPersistence()
            }

            this.hasCookies = !!cookies
        },
    },
    computed: {
        shouldShowFooter() {
            // Adicionar outros
            return this.showCookiesNotification
        },
        showCookiesNotification() {
            return this.settingsShouldShowCookies && !this.hasCookies 
        },
        ...mapGetters({
            isAppLoaded: 'app/isLoaded',
            settingsShouldShowCookies: 'company/shouldShowCookiePolicy',
        }),
    },
    watch: {
        isAppLoaded(v) {
            // Assim que a app carregar verifica se tem cookies
            if (v) {
                this.checkForCookies()
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.notification-footer {
    z-index: 99;
}
</style>