<template>
    <v-icon v-show="shouldShow" color="secondary_font" size="20" class="mr-4 mr-md-0 primary-font-color">far fa-bell</v-icon>
</template>
<script>
export default {
    name: 'NotificationIcon',
    computed: {
        shouldShow() {
            return !this.$route.meta.noNotifications
        },
    },
}
</script>