import _ from 'lodash'

export default function(value) {
    if(!value || !_.isString(value) ) return ''

    let splitSetence = value.split(' ')

    if(splitSetence.includes(''))
        splitSetence = _.compact(splitSetence)

    if(splitSetence.length >= 1 && splitSetence.length < 3)
        return value

    const firstWord = splitSetence[0]
    const lastword  = splitSetence[splitSetence.length -1]

    return `${firstWord} ${lastword}`
}