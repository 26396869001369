<template>
    <BaseModal 
        ref="modal" 
        :title="title" :subtitle="subtitle" :text="text" 
        :hasText="hasText" :hasSubtitle="hasSubtitle"
        :confirm="confirm" :cancel="cancel" :customConfirm="true" 
        max-width="400px"
        confirmColor="primary" cancelColor="background"
        @confirm="handleConfirm" @closed="$emit('closed')"
    >
        <template #icon>
            <v-icon color="#9A9CB8" :size="45">$cp_alert_octagon</v-icon>
        </template>
    </BaseModal>
</template>

<script>
import BaseModal from '@/components/modals/BaseModal'

export default {
    props: {
        hasText: {
            type: Boolean,
            default: false,
            required: false
        },
        hasSubtitle: {
            type: Boolean,
            default: true,
            required: false
        },
    },
    components: {
        BaseModal
    },
    data: vm => {
        return {
            title: vm.$t('modals.ConfirmationModal.title'),
            subtitle: vm.$t('modals.ConfirmationModal.subtitle'),
            text: '',
            confirm: vm.$t('modals.ConfirmationModal.confirm'),
            cancel: vm.$t('modals.ConfirmationModal.cancel'),
        }
    },
    methods: {
        open() {
            this.$emit('opening')
            this.$refs.modal.open()
            this.$emit('opened')
        },
        close() {
            this.$emit('closing')
            this.$refs.modal.close()
            this.$emit('closed')
        },
        showConfirmation(text, useHtml) {
            this.text = text
            this.open()
        },
        handleConfirm() {
            this.$emit('confirm')
            this.close()
        },
    },
}
</script>