export default {
    AuthorizationCard: {
        title: 'Cobrança pendente',
        description: 'O estabelecimento <b>{subsidiary}</b> está pedindo sua permissão para utilizar o saldo disponível.',
        success_message: 'Para autorizar a cobrança diga o código de autorização ao operador de caixa.',
        authorization_code: 'Código de autorização',
        list: {
            balance: 'Saldo disponível',
            total: 'Valor total da compra',
            consumption_points: 'Pontos consumidos',
            balance_after_consumption: 'Saldo após a compra',
        },
    },
}