export default {
    pending_authorization: {
        label: 'Você tem uma cobrança pendente.',
        label_empty: 'Suas cobranças pendentes aparecem aqui.',
        view: 'Ver cobrança',
        error: 'Houve um erro ao buscar sua cobrança pendente.',
    },
    CookiesNotification: {
        errors: {
            generic: 'Ocorreu um erro ao aceitar política de cookies. Tente novamente mais tarde.',
            'agreement-already-exists': 'Você já aceitou a política de cookies.',
        }
    }
}