export default {
    AlertModal: {
        confirm: 'Entendi',
        success: {
            title: 'Sucesso!',
            subtitle: 'Deu tudo certo com sua solicitação',
        },
        error: {
            title: 'Erro...',
            subtitle: 'Houve um erro ao processar sua solicitação',
        }
    },
    ConfirmationModal: {
        title: 'Confirmar ação?',
        subtitle: 'Confirme para concluir a ação',
        text: 'Confirmar troca?',
        confirm: 'Confirmar',
        cancel: 'Cancelar',
    },
    LoadingModal: {
        wait: 'Aguarde um momento...',
    },
    CodeModal: {
        title: 'Confirmar alteração',
        subtitle: 'Insira o código enviado para seu celular',
        confirm: 'Enviar',
        cancel: 'Cancelar',
        errors: {
            length: 'O código tem exatamente 6 números'
        }
    },
    AgeGateModal: {
        title: 'Seja bem-vindo!',
        subtitle: {
            from_and_to: 'Você tem entre {fromAge} e {toAge} anos?',
            from: 'Você tem mais de {fromAge} anos?',
            to: 'Você tem menos de {toAge} anos?',
        },
    },
}