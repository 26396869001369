export default {
    filters                   : 'Filtros',
    and                       : 'e',
    value                     : 'Valor',
    points                    : 'Pontos',
    received                  : 'Recebido',
    hour                      : 'Horário',
    date                      : 'Data',
    expires                   : 'Expira',
    available_withdrawal      : 'Disponível para retirada até {date}',
    available_withdrawal_short: 'Retirada até {date}',
    available_forever         : 'Válido para sempre',
    code_withdrawal           : 'Código para retirada',
    loading                   : 'Carregando...',
    501                       : 'Em breve...',
    amount_points             : '{amount} ponto | {amount} ponto |{amount} pontos',
    amount_points_short       : '{n}pt | {n}pt | {n}pts',
    percentage                : '{n}%',
    category                  : 'Categoria',
    all                       : 'Todos',
    redirect                  : 'Você está sendo redirecionado...',
    no                        : 'Não',
    yes                       : 'Sim',
}