import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import middlewares from './middlewares'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

middlewares.setup(router)

export default router
