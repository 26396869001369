import BaseMiddleware from "./BaseMiddleware"
import store from '@/store'
import $bus from '@/services/bus'
import i18n from '@/i18n'
export default class AuthenticatedMiddleware extends BaseMiddleware {

    /**
     * Avalia se deve executar este middleware ou não
     *
     * @param {Route} to de qual rota está vindo
     * @param {Route} from para qual rota está indo
     *
     * @return {boolean} deve executar este middleware?
     */
    shouldRun(to, from) {
        return to.meta.mustHaveCompleteProfile
    }

    /**
     * Executa ações sobre esta rota
     *
     * @param {Route} to de qual rota está vindo
     * @param {Route} from para qual rota está indo
     *
     * @return {boolean} deve retornar false quando este middleware modifica a rota com next
     */
    handle(to, from, next) {
        let isAuthenticated = store.getters['auth/check']

        if (!isAuthenticated) {
            let redirect = to.path + '?' + (new URLSearchParams(to.query).toString())
            next({ name: 'login', params: { authNeeded: true }, query: { redirect } })
        }

        let user = store.getters['auth/user']

        // Verifica se o usuário tem o cadastro completo
        if (user && !user.hasCompleteBaseInformations()) {
            let redirect = to.path + '?' + (new URLSearchParams(to.query).toString())
            next({ name: 'complete-profile', query: { redirect } })
            return false
        }

        if (user && !user.hasCompleteAddress()) {
            $bus.$emit('generalAlert',
                i18n.t('alerts.CompleteCustomerRegister.text'), {
                    action_router: { name: 'settings'},
                    action_text  : i18n.t('alerts.CompleteCustomerRegister.title'),
                    localStorageConfig: {
                        itemKey  : 'keepCompleteProfileNotificationSilent',
                        value    : true,
                        expiresIn: 7,
                    },
            })
        }

        return true
    }

}