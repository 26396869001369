import $store from '@/store'
import { generateIntegrationToken } from '@/services/generateIntegrationToken'

export default {
    path: '/clube-de-assinatura',
    name: 'subscription',
    props: true,
    async beforeEnter(to, from, next) {
        const { subscription } = $store.getters['company/integrations']
        const customer         = $store.getters['auth/user']

        const { token } = await generateIntegrationToken()

        if(!subscription || !subscription.frontend_url || !customer)
            return

        window.location.href = subscription.frontend_url + `/entrar/login-integracao?code=${token}&email=${customer.email}`
      },
    meta: {
        title: 'Clube de assinatura',
        mustHaveCompleteProfile: true,
    },
}