import ConsolidatedLevelData from '@/models/ConsolidatedLevelData'
import TransitionLevelData   from '@/models/TransitionLevelData'
import SharedSimpleCustomer from '@/shared/models/SimpleCustomer'
import $constants from '@/services/constants'
import moment from 'moment'
import store from '@/store'
import _ from 'lodash'
import { required, email, string, cpf, cnpj, birthday, password,
    length, phone, isnil, required_contact, alpha, address_state, numeric, postal_code, empty
} from '@/utils/validation'

export default class SimpleCustomer extends SharedSimpleCustomer {
    defaults() {
        return {
            ...super.defaults(),
            address_street       : null,
            address_district     : null,
            address_city         : null,
            address_state        : null,
            address_postal_code  : null,
            address_complement   : null,
            address_number       : null,

            // [TODO] Por enquanto é para ficar estático.
            // [TODO] FID-1484 generalizar e implementar interface,
            address_country      : 'BR',
        }
    }

    relationships() {
        return {
            consolidated_level_data: { class: ConsolidatedLevelData, defaultValue: new ConsolidatedLevelData },
            transition_level_data  : { class: TransitionLevelData,   defaultValue: new TransitionLevelData   },
        }
    }

    validation() {
        return {
            email                : required_contact.and(isnil.or(string.and(email))),
            phone                : required_contact.and(isnil.or(string.and(phone))),
            name                 : required.and(string).and(length(1, 191)),
            birthdate            : required.and(birthday),
            document             : this.use_cnpj ? required.and(string).and(cnpj) : required.and(string).and(cpf),
            current_password     : password(6, 30),
            password             : password(6, 30),
            password_confirmation: password(6, 30),
            // avatar_url: ...,
            address_street       : ((string).and(length(0, 191))).or(isnil),
            address_district     : ((string).and(length(0, 191))).or(isnil),
            address_city         : ((string).and(length(0, 191))).or(isnil),
            // address_country      : (string).and(length(1, 191)),
            address_postal_code  : postal_code.or(isnil.or(empty)),
            address_number       : isnil.or(empty).or(numeric).or(string).and(length(0, 60)),
            address_state        : isnil.or(empty).or((alpha).and(length(2)).and(address_state)),

            // [TODO] Por enquanto é para ficar estático.
            // [TODO] FID-1484 generalizar e implementar interface,
            // address_country: , // como está estático não precisa de validação por enquanto
        }
    }

    get consolidated_level() {
        return _.get(this, 'consolidated_level_data.level_data.level')
    }

    get transition_level() {
        return _.get(this, 'transition_level_data.level')
    }

    get consolidated_accumulated_amount() {
        return _.get(this, 'consolidated_level_data.level_data.accumulated_amount')
    }

    get transition_accumulated_amount() {
        return _.get(this, 'transition_level_data.accumulated_amount')
    }

    get age() {
        if (!this.birthdate)
            return undefined

        return moment().diff(this.birthdate, 'years')
    }

    // Métodos da API

    async update() {
        // Por padrão, considera que o país do cliente é BR [TEMP]
        this.address_country = 'BR'

        let data = this.getData([
            'name', 'email', 'phone', 'birthdate', 'document','company_id', 'opt_in',
            'opt_in_marketing', 'password', 'password_confirmation', 'sex', 
            'address_street', 'address_district', 'address_city', 'address_state',
            'address_country', 'address_postal_code', 'address_complement', 'address_number',
        ])

        // Garante que opt_in é booleano e, caso não seja, não envia
        if (typeof(data.opt_in) !== 'boolean')
            delete data.opt_in

        // Garante que opt_in é booleano e, caso não seja, não envia
        if (typeof(data.opt_in_marketing) !== 'boolean')
            delete data.opt_in_marketing

        // Atualiza informações do usuário na API
        // Erros estão sendo tratados no chamador (caller)
        return await this.request({
            url: '/customer',
            method: 'put',
            data
        })
    }

    async updatePassword() {
        let data = this.getData([
          'current_password', 'password', 'password_confirmation'
        ])

        // Atualiza informações do usuário na API
        // Erros estão sendo tratados no chamador (caller)
        return await this.request({
            url: '/customer',
            method: 'put',
            data
        })
    }

    async createViaEmail() {
        let data = this.getData([
            'name', 'email', 'birthdate', 'password',
            // 'password_confirmation',
            'opt_in', 'opt_in_marketing',
            'document',
        ])

        // Garante que opt_in é booleano e, caso não seja, não envia
        if (typeof(data.opt_in) !== 'boolean')
            delete data.opt_in

        // Garante que opt_in é booleano e, caso não seja, não envia
        if (typeof(data.opt_in_marketing) !== 'boolean')
            delete data.opt_in_marketing

        return await store.dispatch('auth/emailRegister', data)
    }

    async createViaFirebase() {
        let data = this.getData([
            // Pega apenas os atributos permitidos no .schema da API
            'name', 'address', 'email', 'phone', 'birthdate', 'document', 'password', 'company_id',
        ])

        return await this.request({
            url: '/customer/create-via-firebase',
            method: 'post',
            data,
        })
    }

    static async resetPassword(identifier) {
        let identifierHeader = $constants.getConstant('CUSTOMER_EMAIL_HEADER_KEY')
        if (this.isValidDocument(identifier) === true)
            identifierHeader = $constants.getConstant('CUSTOMER_DOCUMENT_HEADER_KEY')

        return await this.request({
            url: '/customer/password-reset',
            method: 'get',
            headers: {
                [identifierHeader]: identifier,
            },
        })
    }

    // Métodos úteis

    determineUseCNPJ() {
        return !!this.document && !!(this.document.replace(/\D/g,'').length == 14)
    }

    /**
     * Retorna true caso o cliente tenha todas as informações básicas.
     * Retorna false caso contrário.
     * 
     * @returns {boolean}
     */
    hasCompleteProfile() {
        return this.hasCompleteBaseInformations() && this.hasCompleteAddress() && this.sex
    }

    /**
     * Retorna true caso o cliente tenha todas as informações básicas.
     * Retorna false caso contrário.
     * 
     * @returns {boolean}
     */
    hasCompleteBaseInformations() {
        return (!!this.email || !!this.phone) && !!this.birthdate && !!this.document && (this.login_type != 'EMAIL' || !!this.opt_in)
    }

    /**
     * Retorna true caso o cliente tenha todas as informações de endereço.
     * Retorna false caso contrário.
     * 
     * @returns {boolean}
     */
    hasCompleteAddress() {
        return !!this.address_street && !!this.address_district && !!this.address_city &&
            !!this.address_postal_code && !!this.address_state
    }

    // Retira formatação antes de enviar para o servidor
    formatToBack() {
        // [TODO-L][FID-114] internacionalizar
        // [TODO][FID-648] Corrigir componente InputDate para retornar valores SEM MÁSCARA
        if (this.birthdate) {
            this.birthdate = moment(this.birthdate, 'DD/MM/YYYY').format('YYYY-MM-DD')
        }

        if (this.document) {
            this.document = this.document.replace(/\D/g, '')
        }

        if (this.phone && this.login_type != 'PHONE') {
            this.phone = '+55' + this.phone.replace(/\D/g, '')
        }

        if (this.email && (/[A-Z]/).test(this.email)) {
            this.email = this.email.toLowerCase()
        }
    }

    // Adiciona formatação necessária para exibição
    formatToFront() {
        // [TODO-L][FID-114] internacionalizar
        // [TODO][FID-648] Corrigir componente InputDate para retornar valores SEM MÁSCARA
        if (this.birthdate) {
            this.birthdate = this.birthdate.replace(/\D/g, '').replace(/(\d{4})(\d{2})(\d{2})/g, '$3$2$1')
        }

        if (this.document) {
            this.document = this.document.replace(/\D/g, '')
        }

        if (this.phone && this.login_type != 'PHONE')
            this.phone = this.phone.replace('+55', '').replace(/\D/g, '')

        // Para passar na validação de uma nova submissão, phone/email precisam ser null quando não são utilizados
        if (!this.phone)
            this.phone = null

        if (!this.email)
            this.email = null
    }

    async canAuthenticate(password, code) {
        if (this.login_type == $constants.getConstant('CUSTOMER_LOGIN_TYPE_EMAIL')) {
            let response = await window.firebase.auth().signInWithEmailAndPassword(this.email, password)
                .catch(() => {
                    return false
                })

            if (!response)
                return false

            return true
        }

        let verification = false
        let appVerifier = new window.firebase.auth.RecaptchaVerifier('recaptcha', {
            'size': 'invisible',
        })
        await window.firebase.auth().signInWithPhoneNumber(this.phone, appVerifier)
            .then(async function (confirmationResult) {
                let response = await confirmationResult.confirm(code)
                    .catch(() => {
                        verification = false
                    })

                verification = !!response.user
            })
            .catch(() => {
                verification = false
            })

        return verification
        // if (!verification) {
        //     this.error = true
        //     this.$router.push({ name: 'login' })
        // }
    }

    static isValidDocument(document) {
        // [TODO] Internacionalizar
        return cpf(document) || cnpj(document)
    }
}