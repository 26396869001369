export default {
    subtitle: 'Painel do cliente',

    home: 'Home',
    login: '@:actions.login',
    settings: '@:navbar.settings',
    logout: '@:actions.logout',
    dashboard: '@:views.HomeView.title',
    history: '@:views.HomeView.history.title',
    prizes: 'Produtos',
    product: 'Produto',
    levels: '@:views.HomeView.levels.title',
    profile: '@:views.SettingsView.title',
    faq: 'Perguntas frequentes',
    balance: 'Saldo:',
    promotion: 'Promoção',
    trigger_promotion: 'Ativar promoção',
    subscription: 'Clube de assinaturas',

    auth_needed: 'Crie sua conta agora!',
}