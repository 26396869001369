// import axios from '@/services/axios'
// import { sleep } from '@/services/dev'
import axiosInstance   from '@/services/axios/index'
import dataOrDefault   from '../utils/dataOrDefault'
import CompanyJson     from '@/models/CompanyJson'
import CompanySettings from '@/models/CompanySettings'
// import UpdateTitleMiddleware from '@/router/middlewares/UpdateTitle'
// import router from '@/router'
import _ from 'lodash'

// Initial state
const state = {
    isLoaded           : false,

    // Informações da empresa
    name               : '',

    // Customizações de layout
    program_name           : '',
    logo_url               : '',
    primary_color          : '#3F51B5',
    background_color       : '#f9f9f9',
    background_url         : '',
    font_color             : '#f9f9f9',
    favicon_url            : '',
    landing_page_domain    : '',
    admin_domain           : '',
    display_mode           : 'POINTS',
    hide_cashback          : false,
    hide_points            : false,
    // google_firebase_app_key: {},

    levels: {
        update_lookback: '',
        renovation_interval: '',
    },

    legal: {
        cookie_policy_url: '',
        privacy_policy_url: '',
        terms_url         : '',
    },

    blocked_at          : '',

    // Configurações da empresa (regras etc.)
    settings: {},

    faq: {
        title: 'Perguntas Frequentes',

        items: [],
    },
}

// Getters
const getters = {
    state              : state => state,
    id                 : state => state.id,
    getId              : state => state.id,
    isLoaded           : state => state.isLoaded,
    name               : state => state.name,
    program_name       : state => state.program_name,
    logo_url           : state => state.logo_url,
    primary_color      : state => state.primary_color,
    background_color   : state => state.background_color,
    background_url     : state => state.background_url,
    font_color         : state => state.font_color,
    favicon_url        : state => state.favicon_url,
    landing_page_domain: state => state.landing_page_domain,
    admin_domain       : state => state.admin_domain,
    legal              : state => state.legal,
    blocked_at         : state => state.blocked_at,
    display_mode       : state => state.display_mode || 'POINTS',
    hide_cashback      : state => _.get(state, 'hide_cashback', false),
    hide_points        : state => _.get(state, 'hide_points', false),
    levels             : state => state.levels,
    json               : state => state.json,
    // google_firebase_app_key: state => state.google_firebase_app_key,
    settings           : state => state.settings,
    faq                : state => state.faq,

    cookies_policy_url    : state => _.get(state, 'legal.cookie_policy_url'),
    shouldShowCookiePolicy: state => _.get(state, 'settings.customFields.Cookie.should_show.active'),
    integrations          : state => _.get(state, 'settings.integrations')
}

// Mutations
const mutations = {
    SET_COMPANY_DATA(state, data = {}) {
        dataOrDefault(state, data)

        if (state.favicon_url)
            document.querySelector('#favicon-tag').href = state.favicon_url
    },
    SET_IS_LOADED(state, value = false) {
        state.isLoaded = value
    },
    SET_SETTINGS(state, value) {
        if (!(value instanceof CompanySettings))
            throw new TypeError('Expected a CompanySetting instance')

        state.settings = value
    },
}

// Actions
const actions = {
    async fetchData({ commit }) {
        let domain = window.location.hostname

        let response = await axiosInstance({
            url: `company/get-company-by-domain/${domain}`,
            method: 'get',
        })

        if (!response || !response.data || response.status != 200)
            return

        response = await axiosInstance({
            url: `company/${response.data[0].company_id}`,
            method: 'get',
        })

        if (!response || !response.data || response.status != 200) 
            return

        let company = response.data

        company.levels = {
            ...company.levels,
        }

        commit('SET_COMPANY_DATA', { ...new CompanyJson(company.json), ...company })
        commit('SET_IS_LOADED', true)

        // Atualiza o título da página
        // let updateTitleMiddleware = new UpdateTitleMiddleware
        // updateTitleMiddleware.handle(router.currentRoute, null, null)
    },
    async fetchSettings({ state, commit }) {
        let response = await axiosInstance({
            url: `company/${state.id}/settings`,
            method: 'get',
        })

        if (!response || !response.data)
            return

        let companySettings = new CompanySettings(response.data)
        commit('SET_SETTINGS', companySettings)
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
 