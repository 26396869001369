var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-icon",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.shouldShow,
          expression: "shouldShow"
        }
      ],
      staticClass: "mr-4 mr-md-0 primary-font-color",
      attrs: { color: "secondary_font", size: "20" }
    },
    [_vm._v("far fa-bell")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }