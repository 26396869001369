import LocaleService from '@/services/locale'
import CustomizationService from '@/services/customization'
import CheckAuthorizationService from '@/services/authorization'
import FirebaseService from '@/services/firebase'
// import Auth from '@/auth'

export default async function () {
    await LocaleService.setup(this)
    await CustomizationService.setup(this)
    await CheckAuthorizationService.setup(this)
    await FirebaseService.setup(this)
    // await Auth.setup()
}