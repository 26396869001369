var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-footer",
    { staticClass: "d-block py-0 navbar secondary_font--text small-font" },
    [
      _vm.hasMark || _vm.hasText || _vm.hasLinks
        ? _c(
            "v-row",
            { staticClass: "pt-2 px-0 px-md-4" },
            [
              _c(
                "v-col",
                {
                  staticClass: "py-1",
                  attrs: { cols: "12", xs: "12", sm: "12", md: "6" }
                },
                [
                  _c(
                    "v-row",
                    { attrs: { "no-gutters": "" } },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "d-flex justify-center justify-md-start",
                          attrs: { cols: "12" }
                        },
                        [
                          _vm.hasMark
                            ? _c(
                                "a",
                                {
                                  staticClass:
                                    "text-center secondary_font--text no-decoration-link",
                                  attrs: {
                                    href: _vm.mark.url,
                                    target: "_blank"
                                  }
                                },
                                [_vm._v(_vm._s(_vm.mark.text))]
                              )
                            : _vm._e()
                        ]
                      ),
                      _vm.hasText
                        ? _c(
                            "v-col",
                            {
                              staticClass:
                                "d-flex justify-center justify-md-start",
                              attrs: { cols: "12" }
                            },
                            [
                              _c("span", { staticClass: "text-center" }, [
                                _vm._v(_vm._s(_vm.text))
                              ])
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.$vuetify.breakpoint.smAndDown &&
              (_vm.hasMark || _vm.hasText) &&
              _vm.hasLinks
                ? _c(
                    "v-col",
                    { staticClass: "py-2", attrs: { cols: "12" } },
                    [
                      _c("v-divider", { staticClass: "secondary_font divider" })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.hasLinks
                ? _c(
                    "v-col",
                    {
                      staticClass:
                        "d-flex py-1 align-self-center align-center justify-center justify-md-end decoreted-link",
                      attrs: { cols: "12", xs: "12", sm: "12", md: "6" }
                    },
                    _vm._l(_vm.links, function(link, i) {
                      return _c(
                        "a",
                        {
                          key: i,
                          staticClass:
                            "mx-2 mx-md-4 text-center secondary_font--text",
                          attrs: { href: link.url, target: "_blank" }
                        },
                        [_vm._v(" " + _vm._s(link.text) + " ")]
                      )
                    }),
                    0
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            {
              staticClass:
                "d-flex align-center justify-center pt-2 pt-md-1 pb-1",
              attrs: { cols: "12" }
            },
            [
              _c(
                "a",
                {
                  staticClass:
                    "d-flex align-center justify-center no-decoration-link",
                  attrs: {
                    href: "https://connectplug.com.br",
                    target: "_blank"
                  }
                },
                [
                  _c("span", { staticClass: "mr-1 secondary_font--text" }, [
                    _vm._v("Desenvolvido por")
                  ]),
                  _c("img", {
                    staticClass: "powered-by-cplug",
                    attrs: { src: require("@/assets/logos/logo-extensive.svg") }
                  })
                ]
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }