export default {
    App: {
        system_unavailable: 'Sistema indisponível',
        company_not_found: 'Não encontramos nenhum dado da empresa',
    },
    RegisterView: {
        validation_error   : 'Há erros nos campos abaixo.',
        password_error     : 'Os campos de senha não coincidem. Verifique novamente.',
        email_error        : 'O email já está sendo utilizado.',
        opt_in_error       : 'É necessário concordar com os termos de uso e a política de privacidade.',
        used_document_error: '@:views.SettingsView.alerts.used_document',

        birthdate_error: {
            both: 'O cadastro é permitido somente para quem tem entre {from} e {to} anos de idade.',
            from: 'O cadastro é permitido somente para quem tem mais de {from} anos de idade.',
            to  : 'O cadastro é permitido somente para quem tem menos de {to} anos de idade.',
        },
    },
    LoginView: {
        logout_success: 'Você saiu da sua conta',
        auth_needed: 'Você precisa entrar na sua conta para poder fazer isso',
        reAuth: 'Houve um erro ao reautenticar. Insira suas novas informações de login para entrar',
        tokenExpired: 'Sua sessão expirou! Entre novamente para usar seus pontos',
        welcome: 'Ainda não conhece o {program_name}?',
        welcome_action: 'Quero conhecer',
        welcome_back: 'Bem vindo de volta!',
        login_error: 'As credenciais estão incorretas.',
        register_label: 'Ainda não tem uma conta?',
        firebase_existent_email_error: 'O email utilizado por esta rede social já foi cadastrado. Utilize o login por email com {firebaseEmail}',
        firebase_loading_error: 'Ocorreu um erro de conexão, recarregue a página.',
        
        has_register: 'Já tenho cadastro!',
        sign_in: 'Entrar',
        email: 'e-mail',
        login_with_identifier: 'Fazer login com CPF ou email',
    },
    CompleteProfileView: {
        title: 'Complete o seu cadastro',
        alerts: {
            update_error: '@:views.SettingsView.alerts.update_error',
            used_email: '@:views.SettingsView.alerts.used_email',
            used_phone: '@:views.SettingsView.alerts.used_phone',
            used_document: '@:views.SettingsView.alerts.used_document',
            password_error: '@:views.SettingsView.alerts.password_error',

            default: '@:views.SettingsView.alerts.default',
        }
    },
    EmailConfirmationView: {
        alerts: {
            confirmation_success: 'Email <a href="mailto:{email}">{email}</a> confirmado com sucesso!',
            already_confirmed   : 'O seu email <a href="mailto:{email}">{email}</a> já havia sido confirmado.',
            confirmation_error  : 'Não foi possível confirmar o seu email <a href="mailto:{email}">{email}</a>.'
        }
    },
    HomeView: {
        title: 'Resumo',
        home: 'Home',
        welcome_name: '{name}',
        welcome: 'Bem-vindo!',
        subwelcome: 'Acompanhe aqui o seu saldo',
        balance: {
            title: 'Saldo',
            expire_in: 'Expira em {time_expire} dias'
        },
        LevelCard: {
            title: 'Seu nível',

            customer_level: 'Seu nível atual:',
            more_info: 'Quero saber mais',
            error: 'Ops... Tivemos um problema ao buscar seu nível',
        },
        levels: {
            title: 'Níveis',
            current_level: 'Você está no',
            guarantee_level: 'Mantenha o nível',
            show_levels: 'Ver níveis',
            spendt_amount: 'Pontos trocados',
            level_index: 'Nível {lvl}',
            points: '{n} ponto | {n} ponto | {n} pontos',

            LevelBenefit: {
                title : 'Vantagens do {level_name}',
                points: 'pontos',
            },

            level_balance: {
                amount_balance: {
                    label: 'Pontos totais',
                    value: '{n} pt | {n} pt | {n} pts',
                },
                spendt_amount:  {
                    label: 'Pontos trocados',
                    value: '{n} pt | {n} pt | {n} pts',
                },
            },

            card: {
                benefits: 'Benefícios',
                necessary_accumulated_amount: 'Pontos acumulados necessários: {amount}',
                more_info: 'Quer saber ainda mais?',
                access_details: 'Acessar detalhes',
                error_loading_benefits: 'Erro no carregamento'
            },
            
            customer_level: 'Seu nível: {level}',
            accumulated_amount: 'Pontos acumulados',

            lookback: {
                subtitle : 'Você está no nível {levelName}',
                error: 'Ocorreu um erro ao buscar as informações do seu nível',
            },

            renovation: {
                consolidated: {
                    subtitle: '@:views.HomeView.levels.lookback.subtitle',
                    text: {
                        guarantee_more_than_two_days: 'Ganhe {pointsToGuaranteeLevel} nos próximos {daysToGuaranteeLevel} para continuar no nível {levelName}',
                        guarantee_between_one_and_two_days: 'Ganhe {pointsToGuaranteeLevel} até amanhã para continuar no nível {levelName}!',
                        guarantee_less_than_one_day: 'Você tem meno de 24h para ganhar {pointsToGuaranteeLevel} e continuar no nível {levelName}',
                        maximum: 'Parabéns, você chegou ao {max}, aproveite!',
                        up: 'Acumule pontos para continuar subindo de nível!',

                        hide_points: {
                            guarantee_more_than_two_days: 'Realize compras nos próximos {daysToGuaranteeLevel} para continuar no nível {levelName}',
                            guarantee_between_one_and_two_days: 'Realize compras até amanhã para continuar no nível {levelName}!',
                            guarantee_less_than_one_day: 'Você tem meno de 24h para realizar compras e continuar no nível {levelName}',
                            maximum: 'Parabéns, você chegou ao {max}, aproveite!',
                            up: 'Realize compras para continuar subindo de nível!',
                        },

                        maximum_level: 'nível máximo',
                    },
                },

                transition: {
                    subtitle: 'Seus prêmios',
                    error: 'Ocorreu um erro ao buscar as informações do seus prêmios',

                    first_item_title: 'Pronto para começar?',
                    first_item_description: 'Acumule pontos e ganhe prêmios!',

                    hide_points: {
                        first_item_description: 'Realize compras e ganhe prêmios!',
                    },
                },

                prizes: {
                    receive_with: 'Ganhe com',

                    hide_points: {
                        up_level_to_receive: 'Suba de nível para ganhar prêmios',
                        receive_with: 'Ganhe realizando compras',
                    },
                },
            },
        },
        expire: {
            title: 'Expira em',
            title_points: 'Pontos a expirar',
            title_currency: 'Créditos a expirar',

            overline: '{days} dias',
            prizes: 'Trocar pontos',
        },
        history: {
            title: 'Últimos registros',
            spent_value: 'Quanto você já recuperou',
            received_amount: 'Pontos recebidos até agora',
            show_all: 'Ver todos os registros',
            and_more: 'e mais {q} registros',
            and_more_one: 'e mais 1 registro',
            no_registers: 'Parece que você ainda não fez nenhuma compra'
        },
        last_sale: {
            title: 'Última compra',
            subsidiary_document: 'CNPJ filial: {document}',
            no_sale_title: 'Você ainda não fez nenhuma compra',
            no_sale_subtitle: 'Realize sua primeira compra e comece a ganhar!',
        },
        voucher_withrdrawal: {
            title: 'Minhas trocas',
            no_voucher: 'Sem trocas pendentes'
        }
    },
    Level: {
        title: 'Níveis',
        subtitle : 'Você esta no nível {level_name}',
        level_index: 'Nível {lvl}',
        points: '{n} ponto | {n} ponto | {n} pontos',
        
        // Verificar na FID-1050
        LevelBenefit: {
            // title : 'Vantagens do {level_name}',
            points: 'pontos',
        }
    },
    SettingsView: {
        title: 'Meu perfil',

        send_email_confirmation_title: 'Você ainda não confirmou seu email!',
        // send_email_confirmation_subtitle: 'Se desejar reenviar o email com o link de confirmação, clique no botão ao lado',

        navigation: {
            customer: 'Editar perfil',
            cancel: '@:actions.cancel',
            credentials: 'Mantenha suas informações sempre atualizadas',  
            send_email_confirmation: 'Reenviar email'
        },
        user_customer: {
            title: 'Mantenha suas informações sempre atualizadas!',
            subtitle: 'Mantenha suas informações sempre atualizadas!',
        },
        user_credentials: {
            title: 'Alterar credenciais',
            subtitle: 'Altere suas credenciais sempre que precisar',
        },
        alerts: {
            update_error              : 'Não foi possível atualizar suas informações',
            update_customer_success   : 'Suas informações foram atualizadas!',
            update_credentials_success: 'Suas credenciais foram atualizadas!',
            used_email                : 'O email inserido já está em uso',
            used_phone                : 'O celular inserido já está em uso',
            used_document             : 'O CPF inserido já está em uso',
            wrong_password            : 'A senha inserida está incorreta',
            email_confirmation_sent   : 'Um email de confirmação foi enviado para <a href="mailto:{email}">{email}</a>. Lembre-se de verificar SPAM.',
            send_error                : 'Não foi possível reenviar o email',
            email_confirmed           : 'O email já foi confirmado',

            default: '@:errors.generic',
        }
    },
    Prizes: {
        title: 'Trocar pontos por produtos',
        title_hide_points: 'Produtos',
        title_product: 'Detalhes do Produto',
        back_to_list: 'Voltar para lista',
        filters: {
            title: 'Filtros',
            category: 'Categoria',
            price: 'Preço',
        },
        error: 'Ops... Tivemos um problema ao buscar este produto',

        tabs: {
            list: {
                tab: 'Todos',
                free: 'Grátis',
                
                alerts: {
                    buy_error          : 'Não foi possível finalizar sua troca...',
                    insufficient_funds : 'Você não tem saldo suficiente...',
                    buy_success        : 'Troca realizada com sucesso!',
                    'no-code-available': 'Não existem mais códigos disponíveis para este produto.',
                },

                prize_card: {
                    expires_in: 'Válido por {expires_in} dias',
                    never_expires: 'Válido para sempre!',
                },
            },
            favorites: {
                tab: 'Meus favoritos',
            },
            history_withdrawable: {
                tab: 'A retirar',

                voucher_card: {
                    expires_at: 'Válido até {expires_at}',
                    never_expires: '@:views.Prizes.tabs.list.prize_card.never_expires',
                },
            },
            history_withdrawn: {
                tab: 'Retirados',

                voucher_card: {
                    withdrawn_at: 'Retirado em {withdrawn_at}',
                },
            },
            history_expired: {
                tab: 'Expirados',

                voucher_card: {
                    expired_at: 'Expirado em {expired_at}',
                },
            },
        },

        details: {
            description: 'Descrição',
            trade: 'Quero trocar',
        },
    },
    History: {
        title: 'Histórico',

        list_title: 'Últimos registros',
        spent_value: 'Valor total gasto',
        received_amount: 'Total de pontos recebidos',
        no_registers: '@:views.HomeView.history.no_registers',

        tabs: {
            all: 'Todos',
            fifteen_days: 'Últimos 15 dias',
            thirty_days: 'Últimos 30 dias',
        },
    },
    Faq: {
        title: '@:drawer.faq',
    },
    TriggerPromotionView: {
        title          : 'Ativar promoção',
        title_triggered: 'Promoção ativada!',

        loading        : 'Carregando promoção. Por favor, aguarde.',
        errors         : {
            422    : 'Esta promoção é inválida.',
            404    : 'A promoção com o código "{promotion_code}" não foi encontrada. Verifique o código da promoção e tente novamente.',
            generic: 'Ocorreu um erro ao carregar a promoção. Tente novamente mais tarde.',
            inactive: 'Esta promoção não está ativa no momento.',
        },
        search_by_code: {
            title      : 'Buscar promoção',
            description: 'Para ativar uma promoção, insira o código da promoção no campo abaixo.',
            label      : 'Código da promoção',
            placeholder: 'AXHFI425',
            hint       : 'Digite o código de uma promoção válida',
        },

        actions_title: 'Veja o que você ganhou:',
        filters_title: 'A promoção não foi ativada, pois:',
    },
}