import $store from '@/store'
import $console from '@/services/console'

/**
 * Este serviço lida com as customizações das cores do tema e de textos passados
 * na company
 */
export default new class CustomizationService {

    setup(context) {
        this.update(context)
    }

    update(context) {
        if (!context)
            throw new Error('Deve passar um contexto para atualizar as customizações.')

        let primaryColor   = $store.getters['company/primary_color']
        let secondaryColor = $store.getters['company/background_color']

        if (primaryColor)
            context.$vuetify.theme.themes.light.primary = primaryColor
        
        if (secondaryColor)
            context.$vuetify.theme.themes.light.secondary = secondaryColor
    }

}