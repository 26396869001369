<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="21.729" height="38.629" viewBox="0 0 21.729 38.629"><g transform="translate(0)"><g transform="translate(0)"><path class="b" d="M133.246,14.727l-9.657-7.243a1.21,1.21,0,0,0-1.449,0l-2.511,1.883a6.1,6.1,0,0,1-.386-2.125c0-2.663,1.625-4.829,3.621-4.829s3.622,2.166,3.622,4.829a1.207,1.207,0,0,0,2.414,0C128.9,3.25,126.191,0,122.865,0s-6.036,3.25-6.036,7.243a8.262,8.262,0,0,0,.831,3.6l-5.176,3.882a1.206,1.206,0,0,0-.483.966V36.215a2.417,2.417,0,0,0,2.414,2.414h16.9a2.417,2.417,0,0,0,2.414-2.414V15.693A1.206,1.206,0,0,0,133.246,14.727Zm-1.931,22.695V36.215h-16.9V16.3l4.643-3.481a5.414,5.414,0,0,0,3.807,1.671,1.207,1.207,0,1,0,0-2.414A2.961,2.961,0,0,1,121,11.359l1.866-1.4,8.45,6.338V36.215h0Z" transform="translate(-112)"/></g><g transform="translate(7.243 16.9)"><path class="b" d="M211.621,231.243a1.207,1.207,0,0,1,0-2.414h2.414a1.207,1.207,0,1,0,0-2.414h-1.207v-1.207a1.207,1.207,0,1,0-2.414,0v1.429a3.613,3.613,0,0,0,1.207,7.021,1.207,1.207,0,1,1,0,2.414h-2.414a1.207,1.207,0,0,0,0,2.414h1.207v1.207a1.207,1.207,0,1,0,2.414,0v-1.429a3.613,3.613,0,0,0-1.207-7.021Z" transform="translate(-208 -224)"/></g></g></svg>
</template>

<script>
export default {
    name: 'BalanceIcon',
}
</script>

<style scoped lang="scss">
.b {
    fill: currentColor;
}
</style>