export default {
    AddressFieldset: {
        postal_code: {
            label: 'CEP',
            link_title: 'Não sei meu CEP',
            placeholder: '01001-000',

            alerts: {
                cep_not_exists      : 'O CEP informado não foi encontrado. Verifique e tente novamente.',
                invalid_format      : 'CEP com formato inválido.',
                postal_code_missing : 'O CEP não foi informado.',
                error               : '@:errors.generic',
            }
        },
        street : {
            label: 'Logradouro',
            placeholder: 'R. Cel. Dulcídio',
        },
        number: {
            label: 'Número',
            placeholder: '1100',
        },
        complement: {
            label: 'Complemento',
            placeholder: 'Em frente ao shooping Cristal'
        },
        city: {
            label: 'Cidade',
            placeholder: 'Curitiba',
        },
        district: {
            label: 'Bairro',
            placeholder: 'Batel',
        },
        state: {
            label: 'Estado (UF)',
            placeholder: 'PR',
        },
        country: {
            label: 'País',
            placeholder: 'Brasil',
        }



    }
}